import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class CommonService {
  baseUrl = environment.appUrl;

  constructor(private http: HttpClient) {}

  ///////////  CURRENT APP TYPE //////////////////////////
  private current_app_status = new BehaviorSubject<any>('app_adminpanel');
  cast_current_app_statuse = this.current_app_status.asObservable();

  setCurrentAppStatus(apptype: string) {
    this.current_app_status.next(apptype);
  }

  /////////// COMMON DATA SERVICE ////////
  getFileExtention(filenamewithext): string {
    let dotsplit=filenamewithext.split('.');
    return dotsplit[dotsplit.length-1].trim().toLowerCase();
  }

  getFileType(filenamewithext):string {
    let dotsplit=filenamewithext.split('.');
    let ext=dotsplit[dotsplit.length-1].trim().toLowerCase();
    let imagetypes = [
      'jpg',
      'png',
      'gif',
      'webp',
      'tiff',
      'psd',
      'raw',
      'bmp',
      'heif',
      'indd',
      'jpeg',
    ];
    let doctypes=['txt','doc','docx'];
    let exceltype=['xls','xlsx'];
    let pdftypes=['pdf'];

    if(imagetypes.indexOf(ext)>=0) return 'IMAGE';
    else if(exceltype.indexOf(ext)>=0) return 'EXCEL';
    else if(pdftypes.indexOf(ext)>=0) return 'PDF';
    else  return 'DOC';
  }

  isEqualJson(obj1,obj2):boolean{
    let keys1 = Object.keys(obj1);
    let keys2 = Object.keys(obj2);

    //return true when the two json has same length and all the properties has same value key by key
    return keys1.length === keys2.length && Object.keys(obj1).every(key=>obj1[key]==obj2[key]);
  }
}
