<div class="dbcoltable">
    <h1 class="master__pageheading">{{ this.pageTitle }}</h1>
    <div class="dbcoltable__box">
      <div class="manage__table__box__header">
        <div class="col">
            <div class="row manage__table__box__header__row">
                <div class="manage__table__box__header__row__col">
                    <label class="u-text-medium">Select Table</label>
                    <p-autoComplete
                        [(ngModel)]="selectedTable"
                        [dropdown]="true"
                        [suggestions]="filteredTables"
                        (completeMethod)="filterTable($event)"
                        (onSelect)="onSelectTable($event)"                
                        field="TableName"
                        placeholder="Database Table(s)"
                      >
                      <ng-template let-table pTemplate="item">
                        <div>{{ table.TableDisplayName }}</div>
                      </ng-template>
                    </p-autoComplete>
                </div>
                <div class="manage__table__box__header__row__col">
                  <label class="u-text-medium">Select Activity</label>
                  <p-autoComplete
                    [(ngModel)]="selectedActivity"
                    [dropdown]="true"
                    [suggestions]="filteredActivity"
                    (completeMethod)="filterActivity($event)"            
                    field="activity"
                    placeholder="Database Activity"
                  >
                  <ng-template let-table pTemplate="item">
                    <div>{{ table.activity }}</div>
                </ng-template>
                </p-autoComplete>
                </div>
                <div class="manage__table__box__header__row__col" *ngIf="totalRecords !== undefined">
                    <label class="u-text-medium">Total Records</label>
                    <p class="tot_rec_stl">{{totalRecords}}</p>
                </div>
                <div class="manage__table__box__header__row__col" *ngIf="uploadMessage">
                  <label class="u-text-medium">Status</label>
                  <a  class="fileupload__showstatus" (click)="ShowUploadResult=!ShowUploadResult">
                    <svg class="fileupload__icon">
                        <use xlink:href="./assets/img/sprite.svg#icon-file-text"></use>
                    </svg>
                    Check Status
                </a>
                </div>
            </div>   
            <div class="row manage__table__box__header__row">
                <ng-container *ngIf="selectedActivity && selectedActivity.activity === 'Download'">
                    <div class="u-show-flex u-show-flex__column manage__table__box__header__row__col">
                      <label class="u-text-medium">Batch Size</label>
                      <p-inputNumber
                      [(ngModel)]="Batch_Size"
                      mode="decimal"
                      inputId="withoutgrouping"
                      [useGrouping]="false"
                      [min]="0"
                      >
                      </p-inputNumber>
                    </div>
                    <div class="u-show-flex u-show-flex__column manage__table__box__header__row__col">
                      <label class="u-text-medium">Download Size</label>
                      <p-inputNumber
                      [(ngModel)]="Download_Size"
                      mode="decimal"
                      inputId="withoutgrouping"
                      [useGrouping]="false"
                      [min]="0"
                      >
                      </p-inputNumber>
                    </div>
                </ng-container>
                <ng-container *ngIf="selectedActivity && selectedActivity.activity !== 'Download'">
                    <div class="u-show-flex u-show-flex__column manage__table__box__header__row__col">
                      <label class="u-text-medium">Search Value</label>
                      <input type="text" class="inp-bx-stl" [(ngModel)]="Search_Val" pInputText>
                    </div>
                    <div class="u-show-flex u-show-flex__column manage__table__box__header__row__col">
                      <label class="u-text-medium">{{selectedActivity.activity === 'Append'?'Append':'Replace'}} Value</label>
                      <input type="text" class="inp-bx-stl" [(ngModel)]="Update_Val" pInputText>                      
                    </div>
                    <div class="u-show-flex u-show-flex__column manage__table__box__header__row__col" *ngIf="selectedActivity.activity === 'Append'">
                      <label class="u-text-medium">Delimiter (Eg. '-' , ';' etc.)</label>
                      <input type="text" class="inp-bx-stl" [(ngModel)]="Delimiter" pInputText>                      
                    </div>
                </ng-container>                
                <div class="u-show-flex u-margin-left-auto manage__table__box__header__row__col">
                  <div *ngIf="selectedActivity && selectedActivity.activity === 'Download'">
                    <button [disabled]="disableDownload()" (click)="downloadData('csv')" pTooltip="Download as CSV" tooltipPosition="bottom" pButton pRipple type="button" icon="pi pi-file-o" class="p-button-rounded p-button-secondary btn-dw-stl"></button>
                    <button [disabled]="disableDownload()" (click)="downloadData('xlsx')" pTooltip="Download as EXCEL" tooltipPosition="bottom" pButton pRipple type="button" icon="pi pi-file-excel" class="p-button-rounded p-button-success btn-dw-stl"></button>
                    <button [disabled]="disableDownload()" (click)="downloadData()" pTooltip="Download as PDF" tooltipPosition="bottom" pButton pRipple type="button" icon="pi pi-file-pdf" class="p-button-rounded p-button-danger btn-dw-stl"></button>
                  </div>
                  <div *ngIf="selectedActivity && selectedActivity.activity !== 'Download'">
                    <button
                      type="submit"
                      class="btn btn--green u-margin-right-small"
                      (click)="updateData()"
                    >
                      <svg class="btn__icon">
                        <use xlink:href="./assets/img/sprite.svg#icon-check-square"></use>
                      </svg>
                      &nbsp;Update
                    </button>
                  </div>                  
                </div>
            </div>
        </div>                    
      </div>
      <p-table *ngIf="tblColumnsDtls && tblColumnsDtls.length && selectedActivity" [value]="tblColumnsDtls" [columns]="cols" [reorderableColumns]="false" responsiveLayout="scroll" scrollHeight="400px">
        <ng-template pTemplate="caption">
            <div class="table-header">
                Select field(s) to {{selectedActivity.activity.toLowerCase()}}                
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>                
                <th *ngFor="let col of columns">  
                    <input
                      *ngIf="col.type === 'boolean'"
                      type="checkbox"
                      [id]="'checkAll'"
                      [checked]="checkAll"
                      (change)="selectAllField()"
                    />                 
                    <p *ngIf="col.type !== 'boolean'">{{col.header}}</p>
                </th>              
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
            <tr>
                <td *ngFor="let col of columns">
                  <ng-container *ngIf="col.type==='text'">
                    <p>{{rowData[col.field]}}</p>
                  </ng-container>                                
                  <ng-container *ngIf="col.type==='boolean' && (dropdownManage?true:col.field !== 'view_in_dropdown')">
                    <input
                      type="checkbox"
                      [id]="'showdrop' + col.field + i"
                      [(ngModel)]="rowData[col.field]"
                      (change)="selectField(rowData)"
                    />
                    <label [for]="'showdrop' + col.field + i"></label>
                  </ng-container>                  
                </td>
            </tr>
        </ng-template>
      </p-table>
    </div>  
  </div>

  <!-- Update Status dialog -->
  <div class="popup {{ ShowUploadResult ? 'showpopup':'' }}" id="popup">
    <div class="popup__content">
        <div class="popup__right">
          <a herf="#test" class="popup__close" (click)="ShowUploadResult=false">&times;</a>
          <h2 class="heading-secondary u-margin-bottom-small">
            Status Report - {{selectedTable?selectedTable.TableName:''}}
          </h2>
          <div class="fileupload__statusbar u-margin-top-small">
            <div class="fileupload__statustext">
                <span class="fileupload__statustext--head">
                    <svg class="fileupload__icon">
                        <use xlink:href="./assets/img/sprite.svg#icon-file"></use>
                    </svg>
                    <span class="fileupload__statustext--file">{{uploadMessage}}</span>
                </span>
            </div>
          </div>
        </div>
    </div>
</div>