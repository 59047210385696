import { Component } from '@angular/core';
import { Location } from '@angular/common';
import {
  Router,
  NavigationStart,
  Event as NavigationEnd,
} from '@angular/router';
import { FilterMatchMode, PrimeNGConfig } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { dyndataobject_model } from './_model/dyndataobject_model';

import { AuthenticationService, ConstantsService } from './_services';
import { CommonService } from './_services/common.service';
import { DatasearchService } from './_services/datasearch.service';

@Component({ selector: 'app-root', templateUrl: 'app.component.html' })
export class AppComponent {
  currentUser: any;
  menuDetails: any;
  current_app_status:string="app_adminpanel";
  


  constructor(
    private location: Location,
    private primengConfig: PrimeNGConfig,
    private router: Router,
    private authenticationService: AuthenticationService,
    private datasearchService: DatasearchService,
    private constantService: ConstantsService,
    private commonService:CommonService
  ) {

    // GET LOGGED IN USER
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );

    // If no user logged in need to redirect login page
    this.authenticationService.currentUser.subscribe(res=>{
      this.setCurrentAppName();
      if(res==null){
        setTimeout(() => {
          if(this.constantService.currentAppStatus=='app_adminpanel'){
            this.router.navigate(['/login']);
          }
        }, 500);
        
      }
    })
    //GET CURRENT USER MENU DETAILS
    this.constantService.menuDetails.subscribe((x) => (this.menuDetails = x));


    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event: NavigationEnd) => {

        //console.log(event);
        let eve_r=event as NavigationStart;
        let currenturl=eve_r.url;
        
        //For Table Data
        if(this.current_app_status=='app_table_data'){
          let currentmenuitem= this.menuDetails.filter(x=>x.routeurl=='/master/dynamic_table');
          if(currentmenuitem.length>0){
            this.menuItemChange(currentmenuitem[0]);
          }
        }
        else{
            //Find the current route menuitem
            let currentmenuitem= this.menuDetails.filter(x=>x.routeurl==currenturl);
            if(currentmenuitem.length>0){
              this.menuItemChange(currentmenuitem[0]);
            }else{
              // For Database Column Manage beta
              currentmenuitem = {
                menu_active: 1,
                menu_icon: "./assets/img/sprite.svg#icon-edit-3",
                menu_id: 11,
                menu_is_mstr_downloadcsv: 1,
                menu_is_mstr_downloadexcel: 1,
                menu_is_mstr_downloadpdf: 1,
                menu_is_mstr_glbsearch: 1,
                menu_route_name: "master",
                menu_sub_title: "",
                menu_title: "Database Column Manage",
                menu_uniq_name: "database_column_manage",
                roleacc_is_delete: 1,
                roleacc_is_edit: 1,
                roleacc_is_view: 1,
                routeurl: "/master/database_column_manage"
              }
              this.menuItemChange(currentmenuitem);
            }
        }
      });
    
  }

  ngOnInit() {
    //////// PRIMNG CONFIGURATION //////
    this.primengConfig.ripple = true;
    this.primengConfig.filterMatchModeOptions = {
      text: [
        FilterMatchMode.CONTAINS,
        FilterMatchMode.STARTS_WITH,
        FilterMatchMode.NOT_CONTAINS,
        FilterMatchMode.ENDS_WITH,
        FilterMatchMode.EQUALS,
        FilterMatchMode.NOT_EQUALS,
      ],
      numeric: [
        FilterMatchMode.EQUALS,
        FilterMatchMode.NOT_EQUALS,
        FilterMatchMode.LESS_THAN,
        FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
        FilterMatchMode.GREATER_THAN,
        FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
      ],
      date: [
        FilterMatchMode.DATE_IS,
        FilterMatchMode.DATE_IS_NOT,
        FilterMatchMode.DATE_BEFORE,
        FilterMatchMode.DATE_AFTER,
      ],
    };

    //App Type Change Event
    this.commonService.cast_current_app_statuse.subscribe((val) => {
      this.current_app_status = val;
    });
    this.setCurrentAppName();
  }

  setCurrentAppName(){
    let path = this.location.path();
    path=decodeURIComponent(path);
    if (path.match(/\/vendor_registration\//g) != null || path == '/vendor_registration') {
      this.commonService.setCurrentAppStatus('app_vendor_registration');
    }  
    else if (path.match(/\/table_data\//g) != null || path == '/table_data') {
      this.commonService.setCurrentAppStatus('app_table_data');

    }
    else if (path.match(/\/table_data\//g) != null || path.indexOf('/table_data_public')>-1) {
      this.commonService.setCurrentAppStatus('app_table_data_public');
    }
    else {
      this.commonService.setCurrentAppStatus('app_adminpanel');
    }
  }

  menuItemChange(menuitem) {
    sessionStorage.setItem('currentMenuDtls', JSON.stringify(menuitem));
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}
