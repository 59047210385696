<app-vendor-form
  *ngIf="isShowAddEditForm == true && isSubmitted == false"
  menu_uniq_name="client"
  [field_details]="fieldDtls"
  [field_data]="fieldData"
  [formEditMode]="dataEditMode"
  (onFormSubmitEvent)="formSubmit($event)"
></app-vendor-form>

<!-- After successfull registration -->
<div class="vendor_form__message" *ngIf="isSubmitted">
  <div class="vendor_form__message__head">
    <img src="../assets/img/logo.png" alt="ChemRobotics" />
    <h1>Vendor Registration</h1>
  </div>
  <div class="vendor_form__message__dtls">
    <svg>
      <use xlink:href="./assets/img/sprite.svg#icon-check-square"></use>
    </svg>
    <h2>Registraion details Submitted successfully</h2>
    <p>
      Once ChemRobotics team validate and verify your company detail will be
      enlisted in company directory website (<a
        target="_blank"
        href="https://companydirectory.chemrobotics.com"
        class="vendor_form__message__dtls__link"
        >companydirectory.chemrobotics.com</a
      >)
    </p>
    <p>
      For Product/Compound tagging please download below template spread sheet
      file fill the required detail and send to the email(<a
        class="vendor_form__message__dtls__link"
        >support@chemrobotics.com</a
      >).
    </p>

    <div class="vendor_form__message__dtls__footer">
      <div class="vendor_form__message__dtls__download">
        <i class="bx bxs-spreadsheet"></i>
        <a
          href="https://www.chemrobotics.net/files/SUPPLIER_PROD_FORM_VENDOR_REG_TEMPLATE.xlsx"
          class=""
          >Click Here to Download</a
        >
      </div>
      <div class="vendor_form__message__dtls__newone">
        <i class="bx bxs-plus-circle"></i>
        <a (click)="newOne()" class="">Register New One</a>
      </div>
    </div>
  </div>
</div>

<!-- footer -->
<app-footer
  *ngIf="isShowAddEditForm == true && isSubmitted == false"
></app-footer>
