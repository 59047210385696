import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  //currentUser: any;

  constructor(
      private router: Router,
      private authenticationService: AuthenticationService
  ) {
      //this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit(): void {
  }


  // logout() {
  //     this.authenticationService.logout();
  //     this.router.navigate(['/login']);
  // }

  


}
