import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { filter_model } from '../_model/filter_model';
import { ConstantsService } from './constants.service';

@Injectable({
  providedIn: 'root'
})
export class VendorRegistrationService {

  constructor(private http:HttpClient,private constants:ConstantsService) { }

  GetVendorFields()
  {
    return this.http.get(this.constants.ApiUrl+'/get_vendorformfields');
  }

  Save_Vendordetails(postdata)
  {
    return this.http.post(this.constants.ApiUrl+'/manage_vendordetails',postdata);
  }

  // upload file vendor
  uploadFile(postdata)
  {
    return this.http.post(this.constants.ApiUrl+'/uploadfile_vendor',postdata);
  }

  sendMail_vendor(postdata)
  {
    return this.http.post(this.constants.ApiUrl+'/sendmail_vendor',postdata);
  }

}
