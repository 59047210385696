<form
  class="form master__form"
  [formGroup]="dynForm"
  (ngSubmit)="ondynFormSubmit()"  
>
<!-- HEADING FOR ADD -->
<h2 class="master__form__subheading" *ngIf="this.formEditMode=='ADD'">
  <svg class="btn__icon" >
    <use xlink:href="./assets/img/sprite.svg#icon-file-plus"></use>
  </svg>&nbsp;Add a new record
</h2>
<!-- HEADING FOR VIEW -->
<h2 class="master__form__subheading" *ngIf="this.formEditMode=='VIEW'">
  <svg class="btn__icon" >
    <use xlink:href="./assets/img/sprite.svg#icon-file-text"></use>
</svg>&nbsp;View the record
</h2>
<!-- HEADING FOR EDIT -->
<h2 class="master__form__subheading" *ngIf="this.formEditMode=='EDIT'">
  <svg class="btn__icon" >
    <use xlink:href="./assets/img/sprite.svg#icon-edit"></use>
  </svg>&nbsp;Edit the record
</h2>

  <div class="master__form__actions">
    <button 
      class="btn btn--green" 
      type="submit" 
      [disabled]="dynForm.invalid && dynForm.pristine==true" 
      *ngIf="this.formEditMode!='VIEW'" >
      <svg class="btn__icon">
        <use xlink:href="./assets/img/sprite.svg#icon-check-square"></use>
      </svg>
      &nbsp;Save
    </button>
    <button 
      class="btn btn--secondary" 
      type="button"
      (click)="onDeleteApproveEvent(field_data)" 
      [disabled]="dynForm.invalid && dynForm.pristine==true" 
      *ngIf="this.formEditMode=='VIEW' && isShowApprove==true" >
      <svg class="btn__icon">
        <use xlink:href="./assets/img/sprite.svg#icon-x"></use>
      </svg>
      &nbsp;Delete
    </button>
    <button 
      class="btn btn--green" 
      type="button"
      (click)="onApproveEvent(field_data)" 
      [disabled]="dynForm.invalid && dynForm.pristine==true" 
      *ngIf="this.formEditMode=='VIEW' && isShowApprove==true" >
      <svg class="btn__icon">
        <use xlink:href="./assets/img/sprite.svg#icon-check-circle"></use>
      </svg>
      &nbsp;Approve
    </button>
    <button class="btn btn--white" type="button" (click)="ondynCancel()">
      <svg class="btn__icon" *ngIf="this.formEditMode!='VIEW'">
        <use xlink:href="./assets/img/sprite.svg#icon-x"></use>
      </svg>
      <svg class="btn__icon" *ngIf="this.formEditMode=='VIEW'">
        <use xlink:href="./assets/img/sprite.svg#icon-chevron-left"></use>
      </svg>
      &nbsp;{{this.formEditMode!='VIEW' ? 'Cancel':'Back'}}
    </button>
  </div>

  <ng-container *ngFor="let item of field_details">
    <div class="form__group" *ngIf="item.field_db_col_name!='id' && item.field_ui_is_hide!=1">
      <label [for]="item.field_ui_display_name" class="form__label form__label--master">{{
        item.field_ui_display_name
      }}<span  class="form__group__mandatory">{{item.field_ui_mandatory==1 ? '&lowast;':''}}</span></label>
      <!-- textbox -->
      <input *ngIf="item.field_ui_type=='textbox'"
        [type]="item.field_ui_type"
        class="form__input--master"
        [ngClass]="{
          'form__input--master--invalid':
            (dynForm.controls[item.field_db_col_name].invalid &&
              (dynForm.controls[item.field_db_col_name].dirty ||
                dynForm.controls[item.field_db_col_name].touched)) === true
        }"
        autocomplete="off"
        [placeholder]="'Enter ' + item.field_ui_display_name"
        [formControlName]="item.field_db_col_name"
        [id]="item.field_ui_display_name"
        [readonly]="item.field_frm_is_readonly==1 ? true : false"
      />  
      <!-- password -->
      <input *ngIf="item.field_ui_type=='password'"
        [type]="item.field_ui_type"
        class="form__input--master"
        [ngClass]="{
          'form__input--master--invalid':
            (dynForm.controls[item.field_db_col_name].invalid &&
              (dynForm.controls[item.field_db_col_name].dirty ||
                dynForm.controls[item.field_db_col_name].touched)) === true
        }"
        autocomplete="off"
        [placeholder]="'Enter ' + item.field_ui_display_name"
        [formControlName]="item.field_db_col_name"
        [id]="item.field_ui_display_name"
        [readonly]="item.field_frm_is_readonly==1 ? true : false"
      />  
      <!-- radio  -->
      <ng-container *ngIf="item.field_ui_type=='radio'">
        <div class="form__radio-box">
          <div class="form__radio-group"  *ngFor="let ritem of item.field_sql_data" >
            <input 
              type="radio" 
              [name]="item.field_db_col_name" 
              [value]="ritem.value" 
              [id]="ritem.value"
              [readonly]="item.field_frm_is_readonly==1 ? true : false" 
              class="form__radio-input" 
              [formControlName]="item.field_db_col_name">
            <label class="form__label-radio" [for]="ritem.value" class="form__radio-label">
              <span class="form__radio-button"></span> {{ritem.name}}
            </label>
          </div>
        </div>
      </ng-container>
      <!-- checkbox  -->
      <ng-container *ngIf="item.field_ui_type=='checkbox'">
        <div class="form__checkboxgroup">
          <input 
              type="checkbox" 
              [id]="item.field_db_col_name" 
              [readonly]="item.field_frm_is_readonly==1 ? true : false" 
              [formControlName]="item.field_db_col_name">
              <label [for]="item.field_db_col_name"></label>
        </div>
            
        
      </ng-container>

      <!-- dropdown  -->
      <ng-container *ngIf="item.field_ui_type=='dropdown'">
        <ng-select [items]="item.field_sql_data" 
           bindLabel="name" 
           bindValue="id" 
           placeholder="Select {{item.field_ui_display_name}}"
           [readonly]="item.field_frm_is_readonly==1 ? true : false"
           [formControlName]="item.field_db_col_name"
           >
        </ng-select>
      </ng-container>

      <!-- date -->
      <ng-container *ngIf="item.field_ui_type=='date'">
        <div class="form__date__group">
          <input 
            class="form__date__field" 
            [disabled]="this.formEditMode=='VIEW'" 
            autocomplete="off" 
            placeholder="Click to select a date"
            [readonly]="item.field_frm_is_readonly==1 ? true : false" 
            angular-mydatepicker name="mydate" (click)="dp.toggleCalendar()" [options]="myDpOptions" 
            [formControlName]="item.field_db_col_name"
            #dp="angular-mydatepicker"/>
          <svg class="form__date__icon">
            <use xlink:href="./assets/img/sprite.svg#icon-calendar"></use>
          </svg>
        </div>
      </ng-container>
      
      <!-- textarea -->
      <ng-container *ngIf="item.field_ui_type=='textarea'">
        <textarea
        type="text"
        class="form__input--master"
        [ngClass]="{
          'form__input--master--invalid':
            (dynForm.controls[item.field_db_col_name].invalid &&
              (dynForm.controls[item.field_db_col_name].dirty ||
                dynForm.controls[item.field_db_col_name].touched)) === true
        }"
        autocomplete="off"
        [placeholder]="'Enter ' + item.field_ui_display_name"
        [formControlName]="item.field_db_col_name"
        [id]="item.field_ui_display_name"
        [readonly]="item.field_frm_is_readonly==1 ? true : false"
      ></textarea>
      </ng-container>
      <!-- fileupload -->
      <ng-container *ngIf="item.field_ui_type=='fileupload'">

        <div class="form__fileupload" *ngIf="item.image_edit_mode=='ADD' && this.formEditMode=='EDIT'">
          <a title="Click to cancel" (click)="ondynFileUploadCancel(item)" >  
            <svg>
              <use xlink:href="./assets/img/sprite.svg#icon-x-circle"></use>
            </svg>
          </a>
        </div>
        <p-fileUpload *ngIf="item.image_edit_mode=='ADD'"
          name="myfile[]"
          [accept]="item.field_frm_filetype"
          [multiple]="item.field_frm_fileIsmultiple==1 ? true : false"
          [maxFileSize]="item.field_frm_filesize"
          [fileLimit]="item.field_frm_filemultiplecount"
          [customUpload]="true" 
          [showUploadButton]="false"
          [auto]="true"
          (uploadHandler)="fileUploader($event,item)">
        </p-fileUpload>
       
        <!-- in Edit or view mode -->
        <div class="form__fileupload" *ngIf="item.image_edit_mode!='ADD'">
          <img [attr.src]="item.field_frm_fileurl+this.field_data[item.field_db_col_name]" [alt]="item.field_ui_display_name">
          <a [title]="'Click to edit '+item.field_ui_display_name" *ngIf="item.image_edit_mode=='EDIT'" (click)="item.image_edit_mode='ADD'" >  
            <svg>
              <use xlink:href="./assets/img/sprite.svg#icon-edit"></use>
            </svg>
          </a>
        </div>

      </ng-container>
      <div
        *ngIf="
          dynForm.controls[item.field_db_col_name].invalid &&
          (dynForm.controls[item.field_db_col_name].dirty ||
            dynForm.controls[item.field_db_col_name].touched)
        "
        class="alert alert-danger"
      >
        <div *ngIf="dynForm.controls[item.field_db_col_name].errors.required">
          {{ item.field_ui_display_name }} is required.
        </div>
        <div *ngIf="dynForm.controls[item.field_db_col_name].errors.pattern">
          {{ item.field_ui_display_name }} must be a valid.
        </div>
        <div *ngIf="dynForm.controls[item.field_db_col_name].errors.maxlength">
          {{ item.field_ui_display_name }} must be less than {{item.field_db_size}} characters.
        </div>
      </div>
   
  </div>
  </ng-container>
 
</form>