import { Injectable } from '@angular/core';
//Import for alert
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class ToastserviceService {

  constructor() { }

  showError(msgtitle,msgtext){
    Swal.fire({
      title: msgtitle,
      text: msgtext,
      icon: 'error',
      confirmButtonText: 'Ok',
      customClass:{
        confirmButton:'sweetalret__btn'
      }
    })
  }

  showSuccess(msgtitle,msgtext){
    Swal.fire({
      title: msgtitle,
      text: msgtext,
      icon: 'success',
      confirmButtonText: 'Ok',
      customClass:{
        confirmButton:'sweetalret__btn'
      }
    })
  }

  showWarning(msgtitle,msgtext){
    Swal.fire({
      title: msgtitle,
      text: msgtext,
      icon: 'warning',
      confirmButtonText: 'Ok',
      customClass:{
        confirmButton:'sweetalret__btn'
      }
    })
  } 
  showInfo(msgtitle,msgtext){
    Swal.fire({
      title: msgtitle,
      text: msgtext,
      icon: 'info',
      confirmButtonText: 'Ok',
      customClass:{
        confirmButton:'sweetalret__btn'
      }
    })
  }

  showConfirm(msgtitle,msgtext,callback){
    Swal.fire({
      title: msgtitle,
      text:msgtext,
      icon: 'question',
      showCancelButton: true,
      cancelButtonText:"No",
      confirmButtonText: 'Yes',
      customClass:{
        confirmButton:'sweetalret__btn',
        cancelButton:'sweetalret__btn_cancel'
      }
    }).then((result) => {
      callback(result.isConfirmed);
    })
  }

  
 
}
