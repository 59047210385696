import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-database-manage',
  templateUrl: './database-manage.component.html',
  styleUrls: ['./database-manage.component.scss']
})
export class DatabaseManageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  searchDatabase(event){

  }

}
