import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { dyndataobject_model } from 'src/app/_model/dyndataobject_model';
import { ToastserviceService } from 'src/app/_services/toastservice.service';
import { VendorRegistrationService } from 'src/app/_services/vendor-registration.service';
import { Meta } from '@angular/platform-browser';  

@Component({
  selector: 'app-vendor-registration',
  templateUrl: './vendor-registration.component.html',
  styleUrls: ['./vendor-registration.component.scss'],
})
export class VendorRegistrationComponent implements OnInit {
  constructor(
    private meta: Meta,
    private titleService: Title,
    private toastserviceService: ToastserviceService,
    private vendorRegistrationService: VendorRegistrationService
  ) {

    this.titleService.setTitle('ChemrRobotics - Vendor Registration');

    // this.meta.addTags([
    //   { prefix:"og: http://ogp.me/ns#", name: 'og:title', content: 'ChemRobotics' },
    //   { prefix:"og: http://ogp.me/ns#", name: 'og:url', content: 'https://www.chemrobotics.net/vendor_registration' }  ,
    //   { prefix:"og: http://ogp.me/ns#", name: 'og:image', content: 'https://chemrobotics.net/assets/img/carousel_imgs/1.jpg' },
    //   { prefix:"og: http://ogp.me/ns#", name: 'og:description', content: 'Company Directory is a world-leading chemical industry platform – that can register and list its chemical products, which is accessible to buyers as well as suppliers.' }      
    // ]);  
  }

  ngOnInit(): void {
    
    let regdata = localStorage.getItem('localStorage');
    this.isSubmitted = regdata != null;

    this.loadFieldDetails();
  }

  //////// VENDOR REGISRATION FORM ////
  table_id: number = 3;
  postdata: dyndataobject_model = {
    mode: 'GET_FIELD_DETAILS',
    userid: 1,
    role_id: 1,
    table_id: this.table_id, //Role master id
    form_action: 'ADD',
    form_data: {},
    approveid: 0,
  };
  fieldDtls: any[];
  fieldData: any;
  dataEditMode: string = 'ADD'; // ADD,EDIT,VIEW
  temp_password: string;
  isShowAddEditForm: boolean = false;
  lastClientId: number = 0;
  isSubmitted: boolean = false;

  formSubmit(event: FormGroup) {
    // debugger;
    if (event.valid) {
      let isFormModifiedByUser = event.pristine == false ? true : false;
      if (isFormModifiedByUser) {
        //Confirmation
        this.toastserviceService.showConfirm(
          'Confirmation',
          'Do you want to submit the detail?',
          (confirmed) => {
            if (confirmed) {
              //console.log(event.value);
              this.postdata.form_action = this.dataEditMode;
              const regex_formesc = /\\/gm;
              let formescapestring = JSON.stringify(event.value);
              formescapestring = formescapestring.replace(
                regex_formesc,
                '\\\\'
              );
              let form_data = JSON.parse(formescapestring);
              this.saveRecord(form_data);
            }
          }
        );
      } else {
        this.toastserviceService.showWarning(
          'No Changes Made!',
          'Edit the record and save.'
        );
      }
    } else {
      this.toastserviceService.showError(
        'Invalid Submission!',
        'Please enter required field(s) to submit the registraion.'
      );
    }
  }

  getClientID() {
    //Generate CVRN
    this.postdata.mode = 'GET_LAST_CVRN';
    this.vendorRegistrationService
      .Save_Vendordetails(this.postdata)
      .subscribe((res) => {
        if (res[0].length == 1) {
          this.lastClientId = res[0][0].count;
        } else {
          this.lastClientId = 0;
        }

        this.fieldDtls.forEach((element) => {
          if (element.field_db_col_name == 'clnt_cvrn') {
            element.field_ui_defalutvalue = Number(this.lastClientId) + 1;
          }
        });

        this.isShowAddEditForm = true;

        this.postdata.mode = 'FORM_SUBMIT';
      });
  }

  loadFieldDetails() {
    this.postdata.mode = 'GET_FIELD_DETAILS';
    this.vendorRegistrationService.GetVendorFields().subscribe(
      (res) => {
        console.log(res);
        this.fieldDtls = res[0];

        // Make changes for custom vendor registration
        this.fieldDtls.forEach((field) => {
          //hide cols
          if (
            field.field_db_col_name == 'clnt_cvrn' ||
            field.field_db_col_name == 'clnt_dummy_1' ||
            field.field_db_col_name == 'clnt_dummy_2' ||
            field.field_db_col_name == 'clnt_dummy_3' ||
            field.field_db_col_name == 'clnt_dummy_4' ||
            field.field_db_col_name == 'clnt_dummy_5'
          ) {

            field.field_ui_is_hide=1;
          }
        });

        this.getClientID();
      },
      (error) => {
        this.toastserviceService.showError(
          'Error!',
          'Something went wrong. Please refresh the page and try again!'
        );
      },
      () => {}
    );
  }

  saveRecord(form_data) {
    this.postdata.form_data = form_data;
    this.postdata.mode = 'FORM_SUBMIT';
    // debugger;
    this.vendorRegistrationService.Save_Vendordetails(this.postdata).subscribe(
      (res) => {
        localStorage.setItem('regdata', JSON.stringify(form_data));
        this.isSubmitted = true;
        this.isShowAddEditForm = false;
        this.sendEmail(form_data);
      },
      (error) => {
        this.toastserviceService.showError(
          'Error!',
          'Something went wrong. Please refresh the page and try again!'
        );
      },
      () => {
        this.isShowAddEditForm = false;
      }
    );
  }

  newOne() {
    localStorage.removeItem('regdata');
    this.isSubmitted = false;
    this.isShowAddEditForm = true;
  }

  sendEmail(formdata){
    // debugger;
    //Null values to -
    for (var x in formdata) {
      let data = formdata[x];
      if(data=='' || data==null || data == undefined){
        formdata[x]="-";
      }
    };
     //SEND TO CHEMROBOTICS TEAM
     let emailparams={
      vendordetail:formdata,
    }
    this.vendorRegistrationService.sendMail_vendor(emailparams).subscribe(res=>{
      console.log("Mail Sent");
    });
  }
}
