import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DragulaService } from 'ng2-dragula';
import {MessageService} from 'primeng/api';

//Import for File upload
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from 'ngx-file-drop';
//Import for Xlsx file read
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { ToastserviceService } from 'src/app/_services/toastservice.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatasearchService } from 'src/app/_services/datasearch.service';
import { CommonService } from 'src/app/_services';

//Interface
interface ITemplate {
  column_name: string,
  column_header_name: string
}
interface IDiffCol {
  SNO: string;
  InputColumnName: string,
  ActualColumnName: string,
  dbcol:string,
}
interface IUploadedStatusData {
  filename: string;
  IsValidFile: boolean;
  TotalRecords: number,
  SccessRecords: number,
  ErroRecords: number;
  MismatchColums: IDiffCol[],
  TemplatefieldsCount: number,
  FileFieldsCount: number;
  StatusMessage : string
}

@Component({
  selector: 'app-dyntable-mgmt',
  templateUrl: './dyntable-mgmt.component.html',
  styleUrls: ['./dyntable-mgmt.component.scss'],
  providers:[MessageService]
})
export class DyntableMgmtComponent implements OnInit {
  userid: number = 0;
  current_user_role_id: number = 0;
  pageTitle: string = '';
  currentMenuDtls: any;
  username: string = "";

  // RxJS Subscription is an excellent API for managing many unsubscribe calls.
  // See note below about unsubscribing.
  subs = new Subscription();

  constructor(
    private formBuilder: FormBuilder,
    private dragulaService: DragulaService,
    private toastService: ToastserviceService,
    private datasearchService: DatasearchService,
    private commonService: CommonService
  ) {

    //Current user and menu access detail
    this.currentMenuDtls = JSON.parse(
      sessionStorage.getItem('currentMenuDtls')
    );
    this.pageTitle = this.currentMenuDtls.menu_title;

    // some events have lots of properties, just pick the ones you need
    this.subs.add(
      this.dragulaService
        .dropModel('columns')
        // WHOA
        // .subscribe(({ name, el, target, source, sibling, sourceModel, targetModel, item }) => {
        .subscribe(({ sourceModel, targetModel, item }) => {
          //console.log(targetModel);
          this.tblColumnsDtls = targetModel;
          this.tblColumnsDtls.forEach((item, i) => {
            item.column_order = i + 1;
          });
        })
    );

    const container = document.querySelector('columnform__box');

    this.subs.add(
      this.dragulaService
        .drag('columns')
        // WHOA
        // .subscribe(({ name, el, target, source, sibling, sourceModel, targetModel, item }) => {
        .subscribe(({ name, el, source }) => {
          document.onmousemove = (e) => {
            let event = e || window.event;
            let mouseY = event['pageY'];
            let scrollTop =
              document.getElementById('columnform__box').scrollTop ||
              document.body.scrollTop;
            let scrollBottom =
              scrollTop +
              document.getElementById('columnform__box').clientHeight;
            let elementHeight = el.clientHeight;

            // and check if the event is on top or bottom of the page
            if (mouseY - elementHeight / 2 < scrollTop) {
              this.startScrollingUp();
            } else if (mouseY + elementHeight / 2 > scrollBottom) {
              this.startScrollingDown();
            } else {
              this.stopScrolling();
            }
          };
        })
    );

    this.subs.add(
      this.dragulaService
        .dragend('columns')
        // WHOA
        // .subscribe(({ name, el, target, source, sibling, sourceModel, targetModel, item }) => {
        .subscribe((value) => {
          
          document.onmousemove = null;
          this.stopScrolling();
        })
    );
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.currentMenuDtls = JSON.parse(
        sessionStorage.getItem('currentMenuDtls')
      );
      this.pageTitle = this.currentMenuDtls.menu_title;
    }, 1000);

    let currentUserdtls=JSON.parse(localStorage.getItem('currentUser'));
    this.userid = currentUserdtls.userid;
    this.username=currentUserdtls.username;
    this.current_user_role_id = JSON.parse(
      localStorage.getItem('currentUser')
    ).role_id;

    this.createForm_tbl();

    this.loadTableDetls(1);
    this.loadTableDetls_upload(1);
  }

  ngOnDestroy() {
    // destroy all the subscriptions at once
    this.subs.unsubscribe();
  }

  //#region  Table Config

  //#region Table List
  tblKeyword: string = '';
  tblCurrentKeyword: string = '';
  tblDatas: any = [];
  SearhBox_RowCount = 20;

  //on Virtual scroll end
  onVsEnd(event, currentcount) {
    if (currentcount >= this.SearhBox_RowCount) {
      let newpagenumber: number =
        Math.ceil(currentcount / this.SearhBox_RowCount) + 1;
      console.log(event);
      if (currentcount - 1 == event.endIndex && event.endIndex > 0) {
        this.loadTableDetls(newpagenumber);
      }
    }
  }

  onSearchtable() {
    let CurrentKeyword=this.tblKeyword;
    this.tblCurrentKeyword = CurrentKeyword;
    this.tblDatas = [];
    this.loadTableDetls(1);
  }

  loadTableDetls(newpagenumber) {
    if(newpagenumber==1){
      this.tblDatas=[];
    }
    let postdata = {
      mode: 'GET_TABLE_CONFIG',
      rowscount: this.SearhBox_RowCount,
      pagenumber: newpagenumber,
      table_keyword: this.tblCurrentKeyword,
      userid:this.userid
    };
    this.datasearchService.manageDynTablemgmt(postdata).subscribe(
      (res) => {
        console.log(res);
        this.tblDatas = this.tblDatas.concat(res[0]);
        console.log(this.tblDatas);
      },
      (error) => {
        console.log(error);
        this.toastService.showError(
          'Error!',
          'Something went wrong while getting table details. Please refresh the page and try again!'
        );
      },
      () => {}
    );
  }

  // On Table name click
  onRowSelect(event) {
    //console.log(event);
    let currentValue: string = event.data;
    console.log(currentValue);
  }


  editTable(tbldata) {
    
    console.log(tbldata);
    this.tblFormMode = 'UPDATE';
    this.tableid = tbldata.table_id;
    this.edit_tableformdata=tbldata;

    this.tblForm.controls.table_display_name.setValue(
      tbldata.table_display_name
    );
    this.tblForm.controls.table_label.setValue(tbldata.table_label);
    this.tblForm.controls.table_desc.setValue(tbldata.table_desc);
    this.tblForm.controls.table_users.setValue(JSON.parse(tbldata.table_users));

    this.getColumnDtls(tbldata.table_id);
  }

  deleteTable(tbldata) {
    this.toastService.showConfirm(
      'Are you sure want to delete?',
      `Table Name : ${tbldata.table_display_name}`,
      (confirmed) => {
        if (confirmed) {
          let postdata = {
            mode: 'DELETE_TABLE',
            table_id: this.tableid,
          };
          this.datasearchService.manageDynTablemgmt(postdata).subscribe(
            (res) => {
              console.log(res);
              this.loadTableDetls(1);
              this.toastService.showSuccess(
                'Success',
                `Table Name : ${tbldata.table_display_name} deleted.`
              );
            },
            (error) => {
              console.log(error);
              this.toastService.showError(
                'Error!',
                'Something went wrong while deleting table details. Please refresh the page and try again!'
              );
            },
            () => {}
          );
        }
      }
    );
  }

  getColumnDtls(tableid) {
    let postdata = {
      mode: 'GET_COLUMN_CONFIG',
      table_id: tableid,
    };
    this.datasearchService.manageDynTablemgmt(postdata).subscribe(
      (res) => {
        console.log(res);
        let data=res[0];
        this.tblColumnsDtls = data;
        this.tblColumnsDtls_Temp = Object.assign({},data);
      },
      (error) => {
        console.log(error);
        this.toastService.showError(
          'Error!',
          'Something went wrong while getting table column details. Please refresh the page and try again!'
        );
      },
      () => {}
    );
  }

  //#endregion

  //#region Table Form
  tblForm: FormGroup;
  tblFormMode: string = 'ADD';
  tableid = 0;
  edit_tableformdata:any;
  userList:any[]=[];

  createForm_tbl() {
    this.tblForm = this.formBuilder.group({
      table_display_name: ['', Validators.required],
      table_label: ['', Validators.required],
      table_desc: [''],
      table_users:[null, Validators.required]
    });
    this.tblFormMode = 'ADD';
    this.tableid = 0;

    // Setting current user
    this.tblForm.controls.table_users.setValue([ { user_id: this.userid, user_username: this.username }]);
  }

  searchUsers(event){

    let postdata = {
      mode: 'GET_TABLE_USERS',
      keyword:event.query
    };

    this.datasearchService.manageDynTablemgmt(postdata).subscribe(
      (res) => {
        
        this.userList=res[0];
        
      },
      (error) => {
        console.log(error);
        this.toastService.showError(
          'Error!',
          'Something went wrong while getting user details. Please refresh the page and try again!'
        );
      },
      () => {
      }
    );
  }

  submit_Form_tbl() {
    let tblformdata = this.tblForm.value;
    if (this.tblColumnsDtls.length == 0) {
      this.toastService.showWarning(
        'No Column Detail(s) Present!',
        `Please add column for the table ${tblformdata.table_display_name}`
      );
      return;
    }

    //Compare if any chnages made
    if (this.tblFormMode == 'UPDATE') {
      
      let IsColumsChnages = this.commonService.isEqualJson(
        this.tblColumnsDtls,
        this.tblColumnsDtls_Temp
      );
      let Form_new_value = {
        table_display_name: tblformdata.table_display_name,
        table_label: tblformdata.table_label,
        table_desc: tblformdata.table_desc,
        table_users: tblformdata.table_users,
      };
      let Form_old_value = {
        table_display_name: this.edit_tableformdata.table_display_name,
        table_label: this.edit_tableformdata.table_label,
        table_desc: this.edit_tableformdata.table_desc,
        table_users: this.edit_tableformdata?.table_users,
      };

      let IsFormChanges = this.commonService.isEqualJson(Form_new_value,Form_old_value);

      if(IsColumsChnages==true && IsFormChanges==true){
        this.toastService.showWarning(
          'No Changes Made!',
          `Please edit the data to continue.`
        );
        return;
      }
    }

    let message = '';
    let submsg = '';
    if (this.tblFormMode == 'ADD') {
      message = 'Are you sure want to save?';
      submsg = `Table Name : ${tblformdata.table_display_name}`;
    } else if (this.tblFormMode == 'UPDATE') {
      message = 'Are sure want to update ?';
      submsg = `All data will be cleared in ${tblformdata.table_display_name} ?`;
    }
    this.toastService.showConfirm(message, submsg, (confirmed) => {
      if (confirmed) {
        let postdata = {
          mode: 'ADD_OR_UPDATE_TABLE',
          userid: this.userid,
          table_id: this.tableid,
          table_display_name: tblformdata.table_display_name,
          table_label: tblformdata.table_label,
          table_desc: tblformdata.table_desc,
          table_users: tblformdata.table_users,
          column_data: this.tblColumnsDtls,
        };
        console.log(postdata);
        

        //Save the Table and column details
        this.datasearchService.manageDynTablemgmt(postdata).subscribe(
          (res) => {
            
            this.loadTableDetls(1);
            this.loadTableDetls_upload(1);

            this.toastService.showSuccess(
              'Success!',
              `Table detail saved successfully.`
            );
          },
          (error) => {
            console.log(error);
            this.toastService.showError(
              'Error!',
              'Something went wrong while saving. Please refresh the page and try again!'
            );
          },
          () => {
            this.cleartblForm();
          }
        );
      }
    });
  }

  cleartblForm() {
    this.tblFormMode = 'ADD';
    this.tblForm.reset();
    this.tblColumnsDtls = [];
    this.edit_tableformdata=null;
  }

  //#endregion

  //#region Table Columns
  tblColumnsDtls: any[] = [];
  tblColumnsDtls_Temp: any[] = [];
  scrollInterval: any;

  private startScrollingUp() {
    if (this.scrollInterval) return; // don't set it multiple times
    this.scrollInterval = window.setInterval(
      () => document.getElementById('columnform__box').scrollBy({ top: -10 }),
      100
    );
  }

  private startScrollingDown() {
    if (this.scrollInterval) return;
    this.scrollInterval = window.setInterval(
      () => document.getElementById('columnform__box').scrollBy({ top: 10 }),
      100
    );
  }

  private stopScrolling() {
    window.clearInterval(this.scrollInterval);
    this.scrollInterval = null;
  }

  //Download Template
  async Download_ColumnTemplate() {
    var wb = XLSX.utils.book_new();

    let TemplateData = {};
    TemplateData['Column Name'] = '';
    TemplateData['Column Display Name'] = '';

    var ws = XLSX.utils.json_to_sheet([TemplateData], { skipHeader: false });

    XLSX.utils.book_append_sheet(wb, ws);

    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(
      new Blob([wbout], { type: 'application/octet-stream' }),
      'ColumnUploadTemplate.xlsx'
    );
  }

  addNewColumn() {
    let colscount = this.tblColumnsDtls.length + 1;
    let objcol = {
      column_id: colscount,
      column_header_name: null,
      column_order: colscount,
    };
    this.tblColumnsDtls.push(objcol);
    var columnform__box = document.getElementById('columnform__box');

    if (colscount > 15) {
      this.toastService.showSuccess(
        'Column Added',
        'Empty row added at last. please provide required deatil.'
      );
    }
    columnform__box.scroll({
      top: columnform__box.scrollHeight + 200,
      behavior: 'smooth',
    });
    //columnform__box.scrollTop = columnform__box.scrollHeight+400;
  }

  removeAllColumns() {
    this.tblColumnsDtls = [];
  }

  deleteColumn(objcol: any) {
    let message = '';
    if (objcol.column_header_name) {
      message = `Column Name : ${objcol.column_header_name} Column Display Name : ${objcol.column_display_name}`;
    } else {
      message = 'Current selected empty column will be removed.';
    }
    this.toastService.showConfirm(
      'Are you sure want to delete?',
      message,
      (confirmed) => {
        if (confirmed) {
          let indexdelete = this.tblColumnsDtls.findIndex((ele) => {
            return ele.column_id == objcol.column_id;
          });

          if (indexdelete === -1) {
            return false;
          }
          this.tblColumnsDtls.splice(indexdelete, 1);
          //Reorder the column order
          this.tblColumnsDtls.forEach((item, i) => {
            item.column_order = i + 1;
          });
        }
      }
    );
  }

  //Column Template Upload
  public files: NgxFileDropEntry[] = [];
  async dropped_columns(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

        let file = await this.GetFile(fileEntry);
        let filename = '';
        let totalrecords = 0;
        let IsValidFile = true;
        // Here you can access the real file
        console.log(droppedFile.relativePath, file);

        let ext = droppedFile.relativePath.substring(
          droppedFile.relativePath.lastIndexOf('.') + 1
        );
        if (ext == 'xlsx') {
          IsValidFile = false;
          let filedata = await this.GetFileReaderResult(file);
          console.log(filedata);
          var wb = XLSX.read(filedata, { type: 'binary' });
          var wsname = wb.SheetNames[0];
          var ws = wb.Sheets[wsname];

          let jsondata = XLSX.utils.sheet_to_json(ws, {
            header: 1,
            defval: '',
          });
          console.log(jsondata);

          this.tblColumnsDtls = [];
          jsondata.forEach((item, i) => {
            if (i == 0) {
              return;
            }
            let objcol = {
              column_id: i,
              column_name: 'COL' + i,
              column_header_name: item[0],
              column_display_name: item[1],
              column_order: i,
            };
            this.tblColumnsDtls.push(objcol);
          });
        } else {
          this.toastService.showError(
            'Invalid File',
            'Column Template Support only Excel(.xlsx) file'
          );
        }
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  async GetFile(fileEntry: FileSystemFileEntry) {
    return new Promise<File>((resolve, reject) => {
      fileEntry.file(async (file: File) => {
        resolve(file);
      });
    });
  }

  async GetFileReaderResult(file: File) {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (event: any) => {
        let filedata = reader.result;
        resolve(filedata);
      };
    });
  }

  //#endregion

  //#endregion

  //#region Table Upload

  //#region  Table List

  tblKeyword_upload: string = '';
  tblCurrentKeyword_upload: string = '';
  tblDatas_upload: any = []; // it will assign at first time from tblDatas value
  selectedUploadTable:any; 
  columnConfigData:any[]=[];

  //on Virtual scroll end
  onVsEnd_upload(event, currentcount) {
    if (currentcount >= this.SearhBox_RowCount) {
      let newpagenumber: number =
        Math.ceil(currentcount / this.SearhBox_RowCount) + 1;
      console.log(event);
      if (currentcount - 1 == event.endIndex && event.endIndex > 0) {
        this.loadTableDetls(newpagenumber);
      }
    }
  }

  onSearchtable_upload() {
    let CurrentKeyword=this.tblKeyword_upload;
    this.tblCurrentKeyword_upload = CurrentKeyword;
    this.tblDatas_upload = [];
    this.loadTableDetls_upload(1);
  }

  loadTableDetls_upload(newpagenumber) {
    if(newpagenumber==1){
      this.tblDatas_upload=[];
    }
    let postdata = {
      mode: 'GET_TABLE_CONFIG',
      rowscount: this.SearhBox_RowCount,
      pagenumber: newpagenumber,
      table_keyword: this.tblCurrentKeyword_upload,
      userid:this.userid
    };
    this.datasearchService.manageDynTablemgmt(postdata).subscribe(
      (res) => {
        console.log(res);
        this.tblDatas_upload = this.tblDatas_upload.concat(res[0]);
        if(this.tblDatas_upload.length>0){
          this.tblDatas_upload[0].selected=true;
          this.selectedUploadTable=this.tblDatas_upload[0];
          this.getUploadColumnDtls();
        }
        //console.log(this.tblDatas_upload);
      },
      (error) => {
        console.log(error);
        this.toastService.showError(
          'Error!',
          'Something went wrong while getting table details. Please refresh the page and try again!'
        );
      },
      () => {}
    );
  }

  // On Table name click
  onRowSelect_tblupload(tbldata) {
    //console.log(event);
    this.tblDatas_upload.map(item=>{
      if(item.table_id==tbldata.table_id){
        item.selected=true;
        this.selectedUploadTable=null;
        setTimeout(() => {
          this.selectedUploadTable=item;
          this.getUploadColumnDtls();
        }, 300);
        
      }
      else{
        item.selected=false;
      }
    })
    console.log(tbldata);
  }

  getUploadColumnDtls(){
    let postdata={
      mode:"GET_COLUMN_CONFIG",
      table_id:this.selectedUploadTable.table_id
    }
    this.datasearchService.manageDynTablemgmt(postdata).subscribe(res=>{
      this.columnConfigData=res[0];

    },(error)=>{
      console.log(error);
      this.toastService.showError(
        'Error!',
        'Something went wrong while getting table column details. Please refresh the page and try again!'
      );
    },()=>{

    })
  }

  Download_Template(){
    
    if(this.columnConfigData){
      let TemplateData={};
      var wb = XLSX.utils.book_new();

      this.columnConfigData.forEach(item=>{
        TemplateData[item.column_header_name]="";
      })

      var ws = XLSX.utils.json_to_sheet([
        TemplateData
      ],{skipHeader:false});
      
      XLSX.utils.book_append_sheet(wb, ws);
      
      var wbout = XLSX.write(wb, {bookType:'xlsx', type:'array'});
      saveAs(new Blob([wbout],{type:"application/octet-stream"}), `${this.selectedUploadTable.table_display_name}_template.xlsx`);
    }
    
  }

  Filename: string = " Support only xlsx format file.";
  IsUploading: boolean = false;
  UploadStatus: string = "Uploading..";
  CurrentFileTotalRecords: number = 0;
  CurrentProcessRecords: number = 0;
  ShowUploadResult: boolean = false;
  Templatefields: ITemplate[] = [];
  TemplateTablefields = [];
  FileFields: any=[];
  columns = [{ prop: 'SNO', name: 'S.No' }, { prop: 'ActualColumnName', name: 'Actual Column' }, { prop: 'InputColumnName', name: 'Input Column' }];
  UploadedStatusData: IUploadedStatusData[] = [];
  Totalfiles:number=0;
  UploadedFiles:number=0;
  IsEmptyTable:boolean=false;
  async dropped(files: NgxFileDropEntry[]) {
    
    this.TemplateTablefields=[];
    this.UploadedStatusData = [];
    this.IsUploading = true;
    this.UploadStatus = "Uploading..";
    
    

    
    //Get Template Fields    
    //this.Templatefields = await this.GetTempateFields({tablename:this.Templatetable});
    //Generate Insert string
    // let tablecols:string[]=this.Templatefields.map(item=> "`"+item.TableColumnName+"`");
    // let insertstring:string="("+ tablecols.join(",")+")";
    // console.log(insertstring);
    
    let TemplatefieldsCount: number = this.columnConfigData.length;
    this.files = files;
    this.Totalfiles=files.length;
    this.UploadedFiles=0;
    var reader = new FileReader();
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {

        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        let file = await this.GetFile(fileEntry);
        let filename = "";
        let totalrecords = 0;
        let errorrecords = 0;
        let IsValidFile = false;        
        let FileFieldsCount: number = 0;
        let StatusMessage:string;
        let MismatchColums: IDiffCol[] = [];
        let MatchedColums: IDiffCol[] = [];

        this.IsUploading = true;
        let ext = droppedFile.relativePath.substring(droppedFile.relativePath.lastIndexOf(".") + 1);

        this.Filename = droppedFile.relativePath;
          filename = droppedFile.relativePath;

        if (ext == "xlsx") {

          IsValidFile = false;

          
          // Here you can access the real file
          console.log(droppedFile.relativePath, file);

          //reader.readAsBinaryString(file);
          let filedata = await this.GetFileReaderResult(file);

          //reader.onload = async (data) => {
          //filedata = reader.result;
          var wb = XLSX.read(filedata, { type: 'binary' });
          var wsname = wb.SheetNames[0];
          var ws = wb.Sheets[wsname];

          let jsondata = XLSX.utils.sheet_to_json(ws, { header: 1, defval: '' });
          console.log(jsondata);

          this.FileFields = jsondata[0];
          FileFieldsCount = this.FileFields.length;

          
          
            let CurrentTableName=this.selectedUploadTable.table_display_name;
            MismatchColums=[];
            MatchedColums=[];
            this.Templatefields=this.columnConfigData;
            let tablecols:string[]=this.Templatefields.map(item=> "`"+item.column_name+"`");
            let insertstring:string="("+ tablecols.join(",")+")";
            console.log(insertstring);

            if (this.Templatefields.length > 0) {

              this.Templatefields.map((item, index) => {
                
                let filecolname:string = this.FileFields[index] != null ? this.FileFields[index].trim() : '';
                let databasecolname:string = item.column_header_name;
                let maindatabasecolname:string = item.column_name;
                
                //Check column Exists
                let findactualcol=this.FileFields.find(col=>
                  col.trim().toLowerCase()==databasecolname.trim().toLowerCase()
                );
  
                if(findactualcol!=null)
                {
                  MatchedColums.push({ SNO: (index + 1).toString(), InputColumnName: filecolname , ActualColumnName: databasecolname,dbcol:maindatabasecolname });
                }
                else{
                  MismatchColums.push({ SNO: (index + 1).toString(), InputColumnName: filecolname + `(${filecolname.length.toString()})` , ActualColumnName: databasecolname+ `(${databasecolname.length.toString()})`,dbcol:maindatabasecolname });
  
                }
  
              });
              console.log(MismatchColums);
              console.log(MatchedColums);
              
              let Templatedata=[];
              let Tempdata=jsondata.slice(1);
              
              let Filefieldsfromfile= this.FileFields.map(item=>item.toLowerCase().trim());
              
              for(let i=0; i<Tempdata.length;i++)
              {
                let data=[];
                let TempUploadData={};
                for(let mati=0; mati<MatchedColums.length;mati++)
                {
                  let column=MatchedColums[mati].ActualColumnName.toLowerCase().trim();
                  let columndb=MatchedColums[mati].dbcol.trim();
                  let fileindex=Filefieldsfromfile.indexOf(column);
                  let valueofcolumn="";
                  if(fileindex>=0)
                  {
                    valueofcolumn=Tempdata[i][fileindex]; 
                  }
                  valueofcolumn=valueofcolumn.replace(/[\\]/g, '\\\\')
                      .replace(/[\"]/g, '\\\"')
                      .replace(/[\/]/g, '\\/')
                      .replace(/[\b]/g, '\\b')
                      .replace(/[\f]/g, '\\f')
                      .replace(/[\n]/g, '\\n')
                      .replace(/[\r]/g, '\\r')
                      .replace(/[\t]/g, '\\t')
                      .replace(/\\'/g, "\\'");

                  valueofcolumn=valueofcolumn==null ? "":valueofcolumn;
                  data.push(valueofcolumn);
                  TempUploadData[columndb]=valueofcolumn;                
                }
                //attachment_files temp columns
                TempUploadData['attachment_files']='';
                Templatedata.push(TempUploadData); 
              }
              
              //Uplaod Data
              if (MismatchColums.length == 0) {
                
                // //for slash escape
                // let regex_formesc = /\\/gm;
                let formescapestring=JSON.stringify(Templatedata);
                // formescapestring=formescapestring.replace(regex_formesc,'\\\\');

                 //for single quote esacpe
                 let regex_formesc=/'/gm;
                 formescapestring=formescapestring.replace(regex_formesc,"''");

                // debugger;
                 //escape json
                 
                let uploaddata=JSON.parse(formescapestring);
                let postdata ={ 
                  mode:"UPLOAD_TABLE_DATA",
                  userid:this.userid,
                  table_id:this.selectedUploadTable.table_id,
                  table_data:uploaddata,
                  emptytable:(this.IsEmptyTable==true ? 1 : 0)
                };
                console.log(postdata);

  
                totalrecords = totalrecords+Templatedata.length;
                this.CurrentFileTotalRecords = totalrecords;

               
  
                let res = await this.UploadTemplateData(postdata);
                
                let resdata: any = res;
                console.log(resdata.Message);
                if (resdata.status == "error") {
                  StatusMessage=`Uplaod Failed - ${resdata.Message}`;
                  errorrecords += 1;
                }
                else{
                  StatusMessage="Uploaded Successfully"
                }
              }
              else{
                StatusMessage="Invalid File";
                errorrecords += 1;
                //break;
              }
            
  
            }
            else {
              console.log("No template fields.");
            }
          
          
        }
        else {
          IsValidFile = true;
          errorrecords += 1;
          StatusMessage="Invalid File."
        }

        this.UploadedFiles+=1;

        this.UploadedStatusData.push(
          {
            filename: filename,
            IsValidFile: IsValidFile,
            TotalRecords: totalrecords,
            SccessRecords: (totalrecords - errorrecords),
            ErroRecords: errorrecords,
            MismatchColums: MismatchColums,
            TemplatefieldsCount: TemplatefieldsCount,
            FileFieldsCount: FileFieldsCount,
            StatusMessage:StatusMessage
          });
        //End o Each file read

        //});
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }

     
    
    }

    
    console.log(this.UploadedStatusData);
    //Show File Upload Result
    this.IsUploading = false;
    this.Filename = " Support only xlsx format file.";
    this.ShowUploadResult = true;
  }

  async UploadTemplateData(PostData) {
    return await this.datasearchService.manageDynTablemgmt_sync(PostData);

  }
  
  //#endregion

  //#endregion
}
