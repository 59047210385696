import { Component, OnInit } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { dyndataobject_model } from 'src/app/_model/dyndataobject_model';
import { ConstantsService } from 'src/app/_services';
import { DatasearchService } from 'src/app/_services/datasearch.service';
import { ToastserviceService } from 'src/app/_services/toastservice.service';

@Component({
  selector: 'app-tech-route-gbrnstats',
  templateUrl: './tech-route-gbrnstats.component.html',
  styleUrls: ['./tech-route-gbrnstats.component.scss'],
})
export class TechRouteGbrnstatsComponent implements OnInit {
  userid: number = 0;
  current_user_role_id: number = 0;
  table_id: number = 5;
  refreshTableSubject$: Subject<boolean> = new Subject<boolean>();
  tableSettingsSubject$: BehaviorSubject<any> = new BehaviorSubject<any>({
    menu_is_mstr_glbsearch: false,
    menu_is_mstr_downloadcsv: false,
    menu_is_mstr_downloadexcel: true,
    menu_is_mstr_downloadpdf: false,
  });
  postdata: dyndataobject_model = {
    mode: 'FORM_SUBMIT',
    userid: this.userid,
    role_id: this.current_user_role_id,
    table_id: this.table_id, //Role master id
    form_action: 'ADD',
    form_data: {},
    approveid: 0,
  };
  pageTitle: string = '';
  currentMenuDtls: any;
  isShowAddEditForm: boolean = false;
  fieldDtls: any[];
  fieldData: any;
  dataEditMode: string = 'ADD'; // ADD,EDIT,VIEW
  temp_password: string;
  pendingApprovalCnt: number = 0;

  //Page level Variables
  lastClientId: number = 0;

  IsShowUpload: boolean = false;
  IsShowProcessInfo = false;
  IsShowProcessSuccess = false;
  IsShowProcessError = false;

  ExtractProcess = 'COMPLETED';
  polling: any;

  constructor(
    private constantService: ConstantsService,
    private toastserviceService: ToastserviceService,
    private datasearchService: DatasearchService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.currentMenuDtls = JSON.parse(
        sessionStorage.getItem('currentMenuDtls')
      );
      this.currentMenuDtls.menu_is_mstr_downloadcsv = 0;
      this.currentMenuDtls.menu_is_mstr_downloadpdf = 0;
      this.currentMenuDtls.roleacc_is_view = 0;
      this.currentMenuDtls.isShowAddNewButton = false;
      this.currentMenuDtls.isShowApprovalButton = false;
      this.currentMenuDtls.menu_is_mstr_upload = true;
      this.tableSettingsSubject$.next(this.currentMenuDtls);
      this.pageTitle = this.currentMenuDtls.menu_title;
    }, 1000);

    this.userid = JSON.parse(localStorage.getItem('currentUser')).userid;
    this.current_user_role_id = JSON.parse(
      localStorage.getItem('currentUser')
    ).role_id;

    this.postdata = {
      mode: 'FORM_SUBMIT',
      userid: this.userid,
      role_id: this.current_user_role_id,
      table_id: this.table_id, // master table id
      form_action: 'ADD',
      form_data: {},
    };

    this.getTotalPendingApproval();
    this.getStatus();
    this.startChecking();
  }

  onAddNew(form_data) {
    // Adding ChemRobotics Vendor Registration No (CVRN) Auto Genearte for new record
    form_data.form_fielddata.forEach((element) => {
      if (element.field_db_col_name == 'clnt_cvrn') {
        element.field_ui_defalutvalue = Number(this.lastClientId) + 1;
      }
    });
    this.showForm('ADD', form_data);
  }
  viewForm(form_data) {
    this.showForm('VIEW', form_data);
  }
  editForm(form_data) {
    this.showForm('EDIT', form_data);
  }
  deleteRecord(form_data) {
    this.toastserviceService.showConfirm(
      'Confirm',
      'Are you sure want to delete this record ?',
      (confirmed) => {
        if (confirmed) {
          this.dataEditMode = 'DELETE';
          this.saveRecord(form_data);
        }
      }
    );
  }

  formSubmit(event: FormGroup) {
    if (event.valid) {
      let isFormModifiedByUser = event.pristine == false ? true : false;
      if (isFormModifiedByUser) {
        //Confirmation
        this.toastserviceService.showConfirm(
          'Confirm',
          'Do you want to save the record?',
          (confirmed) => {
            if (confirmed) {
              //console.log(event.value);
              this.postdata.form_action = this.dataEditMode;
              const regex_formesc = /\\/gm;
              let formescapestring = JSON.stringify(event.value);
              formescapestring = formescapestring.replace(
                regex_formesc,
                '\\\\'
              );
              let form_data = JSON.parse(formescapestring);
              this.saveRecord(form_data);
            }
          }
        );
      } else {
        this.toastserviceService.showWarning(
          'No Changes Made!',
          'Edit the record and save.'
        );
      }
    } else {
      this.toastserviceService.showError(
        'Invalid Submission!',
        'Please enter required field(s) to save the record.'
      );
    }
  }
  formCancel(event: FormGroup) {
    //Check Form changes happen
    if (this.dataEditMode == 'ADD' || this.dataEditMode == 'EDIT') {
      let isFormModifiedByUser = event.pristine == false ? true : false;
      if (isFormModifiedByUser) {
        this.toastserviceService.showConfirm(
          'Confirm',
          'you have unsaved chnages. Are you sure want to leave?',
          (confirmed) => {
            this.isShowAddEditForm = !confirmed;
          }
        );
      } else {
        this.isShowAddEditForm = false;
      }
    } else {
      this.isShowAddEditForm = false;
    }
  }

  /////// PAGE LEVEK HELPER METHODS //////
  showForm(mode, form_data) {
    this.dataEditMode = mode;
    this.isShowAddEditForm = true;
    this.fieldDtls = form_data.form_fielddata;
    this.fieldData = form_data.row_data;
  }

  saveRecord(form_data) {
    this.postdata.form_action = this.dataEditMode;
    this.postdata.form_data = form_data;
    this.datasearchService.GetDynTableData(this.postdata).subscribe(
      (res) => {
        //console.log(res);
        this.refreshTableSubject$.next(true); //refresh the table
        this.toastserviceService.showSuccess(
          'Success!',
          `Record ${this.dataEditMode == 'ADD' ? 'Saved' : ''}${
            this.dataEditMode == 'EDIT' ? 'Updated' : ''
          }${
            this.dataEditMode == 'DELETE' ? 'Deleted' : ''
          }.Please approve changes in approval section.`
        );
        this.getTotalPendingApproval();
      },
      (error) => {
        this.toastserviceService.showError(
          'Error!',
          'Something went wrong. Please refresh the page and try again!'
        );
      },
      () => {
        this.isShowAddEditForm = false;
      }
    );
  }
  approveRecord(row_data) {
    this.postdata.mode = 'FORM_APPROVE';
    this.postdata.approveid = row_data.id;
    this.datasearchService.GetDynTableData(this.postdata).subscribe(
      (res) => {
        //console.log(res);
        this.refreshTableSubject$.next(true); //refresh the table
        this.toastserviceService.showSuccess('Success!', `Record Approved.`);
        this.getTotalPendingApproval();
      },
      (error) => {
        this.toastserviceService.showError(
          'Error!',
          'Something went wrong. Please refresh the page and try again!'
        );
      },
      () => {
        this.isShowAddEditForm = false;
      }
    );
  }
  deleteApproveRecord(row_data) {
    this.toastserviceService.showConfirm(
      'Confirm',
      'Are sure want to delete the record?',
      (isconfiremed) => {
        if (isconfiremed) {
          this.postdata.mode = 'FORM_APPROVE_DELETE';
          this.postdata.approveid = row_data.id;
          this.fieldData = row_data;
          this.datasearchService.GetDynTableData(this.postdata).subscribe(
            (res) => {
              //console.log(res);
              this.refreshTableSubject$.next(true); //refresh the table
              this.toastserviceService.showSuccess(
                'Success!',
                `Approval Record Deleted/Rejected.`
              );
              this.getTotalPendingApproval();
            },
            (error) => {
              this.toastserviceService.showError(
                'Error!',
                'Something went wrong. Please refresh the page and try again!'
              );
            },
            () => {
              this.isShowAddEditForm = false;
            }
          );
        }
      }
    );
  }
  getTotalPendingApproval() {
    this.postdata.mode = 'GET_TOTAL_PENDING';
    this.datasearchService.GetDynTableData(this.postdata).subscribe((res) => {
      if (res[0].length == 1) {
        this.pendingApprovalCnt = res[0][0].count;
      } else {
        this.pendingApprovalCnt = 0;
      }

      this.postdata.mode = 'FORM_SUBMIT';
    });
  }

  getStatus() {
    this.datasearchService
      .common_config(
        { mode: 'GET', key: 'technical_route_gbrn_count_status', value: '' },
        true
      )
      .subscribe({
        next: (res) => {
          let data: any = res;
          this.ExtractProcess = data[0][0].ValueText;
          console.log(` Current Status : ${this.ExtractProcess}`);
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  startChecking() {
    this.polling = setInterval(() => {
      console.log('Checking Staus..!');
      this.getStatus();
    }, 5000);
  }

  showUpload() {
    this.IsShowProcessInfo = false;
    this.IsShowProcessSuccess = false;
    this.IsShowProcessError = false;
    this.IsShowUpload = true;
  }

  uploadCompleted() {
    this.IsShowProcessInfo = true;
    this.IsShowProcessSuccess = false;
    this.IsShowProcessError = false;
    this.ExtractProcess = 'INPROGRESS';
    //Process GET GBRN Count Extration
    this.datasearchService.technicalRouteProcessGBRNCount({}).subscribe({
      next: (res) => {
        this.IsShowProcessInfo = false;
        this.IsShowProcessSuccess = true;
        this.IsShowProcessError = false;
      },
      error: (err) => {
        this.IsShowProcessInfo = false;
        this.IsShowProcessSuccess = false;
        this.IsShowProcessError = true;
      },
    });
  }

  ngOnDestroy() {
    clearInterval(this.polling);
  }
}
