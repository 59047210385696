<div class="master">
    <h1 class="master__pageheading">{{this.pageTitle}}</h1>
    <div class="master__content">
      <!-- Dynamic Table -->
      <app-dynamic-table *ngIf="isShowAddEditForm == false"
        [TableID]="this.table_id"
        [tableSettingsSubject]="tableSettingsSubject$.asObservable()"
        TableReportTitle="Client Master"
        (onAddNew)="onAddNew($event)"
        (onView)="viewForm($event)"
        (onEdit)="editForm($event)"
        (onDelete)="deleteRecord($event)"
        (onApprove)="approveRecord($event)"
        (onDeleteApprove)="deleteApproveRecord($event)"
        [refreshTableSubject]="refreshTableSubject$.asObservable()"
        [approvalcount]="pendingApprovalCnt"
      ></app-dynamic-table>
      <!-- Dynamic Form -->
      <app-dynamic-form
        *ngIf="isShowAddEditForm == true"
        menu_uniq_name="client"
        [field_details]="fieldDtls"
        [field_data]="fieldData"
        [formEditMode]="dataEditMode"
        (onFormSubmitEvent)="formSubmit($event)"
        (onFormCancelEvent)="formCancel($event)"
        (onApprove)="approveRecord($event)"
        (onDeleteApprove)="deleteApproveRecord($event)"
      ></app-dynamic-form>
    </div>
  </div>