<div class="dyntable">
  <!-- Table Header -->
  <div class="dyntable__header">
    <input
      *ngIf="this.TableSettings.menu_is_mstr_glbsearch"
      type="text"
      class="form__input border-light"
      autocomplete="on"
      placeholder="Search"
      (input)="onGlobalSearch($event.target.value)"
    />
    <button
      class="btn btn--white btn--round"
      type="button"
      title="Previous Page"
      (click)="prev()"
      [disabled]="isFirstPage_var"
    >
      <i class="pi pi-chevron-left"></i>
    </button>
    <button
      class="btn btn--white btn--round"
      type="button"
      title="Next Page"
      (click)="next()"
      [disabled]="isLastPage_var"
    >
      <i class="pi pi-chevron-right"></i>
    </button>
    <button
      *ngIf="showApproveView == false && (this.TableSettings.isShowApprovalButton ?? true)==true"
      class="btn btn--grey"
      type="button"
      title="Click here view approvals"
      (click)="onApproveViewTblEvent(true)"
    >
      <svg class="btn__icon">
        <use xlink:href="./assets/img/sprite.svg#icon-check-circle"></use>
      </svg>
      &nbsp;Approval(s) ({{ approvalcount }})
    </button>
    <button
      *ngIf="showApproveView == true && (this.TableSettings.isShowApprovalButton ?? true)==true"
      class="btn btn--grey"
      type="button"
      title="Click here view approved records"
      (click)="onApproveViewTblEvent(false)"
    >
      <svg class="btn__icon">
        <use xlink:href="./assets/img/sprite.svg#icon-file-text"></use>
      </svg>
      &nbsp;View Records(s)
    </button>
    <button *ngIf="this.TableSettings.isShowAddNewButton ?? true"
      class="btn btn--green"
      type="button"
      title="Add New Record"
      (click)="onAddNewEvent()"
    >
      <svg class="btn__icon">
        <use xlink:href="./assets/img/sprite.svg#icon-plus"></use>
      </svg>
      &nbsp;Add New
    </button>
    <button
      *ngIf="this.TableSettings.menu_is_mstr_upload ?? false"
      class="btn btn--white btn--round"
      type="button"
      title="Upload"
      (click)="uploadEvent()"
     >
    <i class="pi pi-upload"></i>
    </button>
    <button
      *ngIf="this.TableSettings.menu_is_mstr_downloadcsv"
      class="btn btn--white btn--round"
      type="button"
      title="Download CSV"
      (click)="exportExcel('csv')"
    >
      <i class="pi pi-file"></i>
    </button>
    <button
      *ngIf="this.TableSettings.menu_is_mstr_downloadexcel"
      class="btn btn--white btn--round"
      type="button"
      title="Download Excel"
      (click)="exportExcel('xlsx')"
    >
      <i class="pi pi-file-excel u-color-greendark"></i>
    </button>
    <button
      *ngIf="this.TableSettings.menu_is_mstr_downloadpdf"
      class="btn btn--white btn--round"
      type="button"
      title="Download Pdf"
      (click)="exportPdf()"
    >
      <i class="pi pi-file-pdf u-color-red"></i>
    </button>
  </div>
  <!-- Table Content : View Records-->
  <div class="dyntable__content">
    <p-table
      #dt
      [columns]="cols"
      [value]="products_current_datasource"
      [style]="{ width: '100%' }"
      [resizableColumns]="true"
      columnResizeMode="expand"
      styleClass="p-datatable-gridlines"
      responsiveLayout="auto"
      scrollDirection="horizontal"
      [scrollable]="true"
      scrollHeight="620px"
      [lazy]="true"
      (onLazyLoad)="loadLazyData($event)"
      [lazyLoadOnInit]="false"
      [paginator]="true"
      [(first)]="first"
      (onPage)="paginate($event)"
      [rows]="rows"
      [totalRecords]="totalRecords"
      [loading]="loading_table"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [rowsPerPageOptions]="[5, 10, 15, 20, 25, 50, 100, 150]"
      [globalFilterFields]="globalfilterFields"
      class="table_agro"
    >
      <ng-template pTemplate="colgroup" let-columns>
        <colgroup>
          <col
            *ngFor="let col of columns"
            [ngStyle]="{ width: col.field_ui_tblcolwidth }"
          />
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="flex-basis: 100px"></th>
          <th
            [ngStyle]="{ width: col.field_ui_tblcolwidth }"
            [style]="{ 'flex-basis': col.field_ui_tblcolwidth }"
            *ngFor="let col of columns"
            [pSortableColumn]="col.field_db_col_name"
            pResizableColumn
          >
            {{ col.field_ui_display_name }}
            <p-sortIcon [field]="col.field_db_col_name"></p-sortIcon>
          </th>
        </tr>
        <tr>
          <th style="flex-basis: 100px"></th>
          <th
            *ngFor="let col of columns"
            [ngStyle]="{ width: col.field_ui_tblcolwidth }"
            [style]="{ 'flex-basis': col.field_ui_tblcolwidth }"
          >
            <p-columnFilter
              [type]="
                col.field_ui_type == 'textbox' ? 'text' : col.field_ui_type
              "
              [field]="col.field_db_col_name"
              matchMode="contains"
              [showOperator]="false"
              [showAddButton]="false"
              [display]="col.field_ui_type == 'date' ? 'menu' : 'row'"
            ></p-columnFilter>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td style="flex-basis: 100px" class="dyntable__content__actionbox">
            <!-- View Button -->
            <a
              class="btn--link u-margin-right-vvsmall"
              title="View"
              (click)="onViewEvent(rowData)"
              *ngIf="this.TableSettings.roleacc_is_view"
            >
              <svg>
                <use xlink:href="./assets/img/sprite.svg#icon-eye"></use>
              </svg>
            </a>
            <!-- Edit Button -->
            <a
              *ngIf="this.TableSettings.roleacc_is_edit && showApproveView == false"
              class="btn--link u-margin-right-vvsmall"
              title="Edit"
              (click)="onEditEvent(rowData)"
            >
              <svg>
                <use xlink:href="./assets/img/sprite.svg#icon-edit"></use>
              </svg>
            </a>
            <!-- Delete Button -->
            <a
              *ngIf="this.TableSettings.roleacc_is_delete && showApproveView == false"
              class="btn--link u-margin-left-vvsmall"
              title="Delete"
              (click)="onDeleteEvent(rowData)"
            >
              <svg>
                <use xlink:href="./assets/img/sprite.svg#icon-trash-2"></use>
              </svg>
            </a>
            <!-- Delete Approval Button -->
            <a
              *ngIf="this.TableSettings.roleacc_is_delete && showApproveView == true"
              class="btn--link u-margin-right-vvsmall"
              title="Delete"
              (click)="onDeleteApproveEvent(rowData)"
            >
              <svg>
                <use xlink:href="./assets/img/sprite.svg#icon-trash-2"></use>
              </svg>
            </a>
            <!-- Approve Button -->
            <a
              *ngIf="this.TableSettings.roleacc_is_edit && showApproveView == true"
              class="btn--link"
              title="Approve"
              (click)="onApproveEvent(rowData)"
            >
              <svg>
                <use
                  xlink:href="./assets/img/sprite.svg#icon-check-circle"
                ></use>
              </svg>
            </a>
          </td>
          <td
            *ngFor="let col of columns"
            [ngStyle]="{ width: col.field_ui_tblcolwidth }"
            [style]="{ 'flex-basis': col.field_ui_tblcolwidth }"
          >
            <!-- if not file type fileupload and checkbox -->
            <ng-container *ngIf="col.field_ui_type != 'fileupload' && col.field_ui_type != 'checkbox'">
              {{
                col.field_ui_type != "dropdown"
                  ? rowData[col.field_db_col_name]
                  : findNamefrmID(rowData, col.field_db_col_name)
              }}
            </ng-container>
            <!-- if file type and image type -->
            <ng-container
              *ngIf="
                col.field_ui_type == 'fileupload' &&
                col.field_frm_filetype == 'image/*'
              "
            >
              <img
                [attr.src]="
                  col.field_frm_fileurl + rowData[col.field_db_col_name]
                "
                [alt]="rowData[col.field_db_col_name]"
                class="dyntable__content__img"
              />
            </ng-container>
            <!-- if not file type fileupload and checkbox -->
            <ng-container *ngIf="col.field_ui_type == 'checkbox'">
                {{
                  rowData[col.field_db_col_name] == 1
                    ? 'Yes'
                    : 'No'
                }}
            </ng-container>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
