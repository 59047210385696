<div class="master">
  <h1 class="master__pageheading">{{this.pageTitle}}</h1>
  <div class="master__content">
    <!-- Dynamic Table -->
    <app-dynamic-table *ngIf="isShowAddEditForm == false"
      [TableID]="this.table_id"
      [tableSettingsSubject]="tableSettingsSubject$.asObservable()"
      TableReportTitle="Role Master"
      (onAddNew)="onAddNew($event)"
      (onView)="viewForm($event)"
      (onEdit)="editForm($event)"
      (onDelete)="deleteRecord($event)"
      (onApprove)="approveRecord($event)"
      (onDeleteApprove)="deleteApproveRecord($event)"
      [refreshTableSubject]="refreshTableSubject$.asObservable()"
      [approvalcount]="pendingApprovalCnt"
    ></app-dynamic-table>
    <!-- Dynamic Form -->
    <app-dynamic-form
      *ngIf="isShowAddEditForm == true"
      menu_uniq_name="role"
      [field_details]="fieldDtls"
      [field_data]="fieldData"
      [formEditMode]="dataEditMode"
      (onFormSubmitEvent)="formSubmit($event)"
      (onFormCancelEvent)="formCancel($event)"
      (onApprove)="approveRecord($event)"
      (onDeleteApprove)="deleteApproveRecord($event)"
    ></app-dynamic-form>
    <!-- Menu Details for Adding setting Access for Role -->
    <div class="roleaccess" *ngIf="isShowAddEditForm==true" [ngClass]="{'roleaccess--disable':this.dataEditMode=='VIEW'}">
      <h1 class="roleaccess__heading"><svg class="btn__icon" >
        <use xlink:href="./assets/img/sprite.svg#icon-user-check"></use>
      </svg>&nbsp; Provide the below page/menu wise access</h1>
      <div class="roleaccess__item">
        <span>  </span>
        <div class="roleaccess__item__heading">
          <svg>
            <use xlink:href="./assets/img/sprite.svg#icon-eye"></use>
          </svg>
          <span>View</span>
        </div>
        <div class="roleaccess__item__heading">
          <svg>
            <use xlink:href="./assets/img/sprite.svg#icon-edit"></use>
          </svg>
          <span>Edit</span>
        </div>
        <div class="roleaccess__item__heading">
          <svg>
            <use xlink:href="./assets/img/sprite.svg#icon-trash-2"></use>
          </svg>
          <span>Delete</span>
        </div>
      </div>
      <div class="roleaccess__item" *ngFor="let item of allMenuDtls">
        <span><svg class="roleaccess__item__icon">
          <use xlink:href="./assets/img/sprite.svg#icon-menu"></use>
        </svg>&nbsp;{{item.menu_title}}</span>
        <a class="roleaccess__item__value" (click)="item.roleacc_is_view=item.roleacc_is_view==0 ? 1: 0" [ngClass]="{'roleaccess__item__value--yes':item.roleacc_is_view==1,'roleaccess__item__value--no':item.roleacc_is_view==0}">
          <svg>
            <use xlink:href="./assets/img/sprite.svg#icon-check"></use>
          </svg>
        </a>
        <a class="roleaccess__item__value" (click)="item.roleacc_is_edit=item.roleacc_is_edit==0 ? 1: 0" [ngClass]="{'roleaccess__item__value--yes':item.roleacc_is_edit==1,'roleaccess__item__value--no':item.roleacc_is_edit==0}">
          <svg>
            <use xlink:href="./assets/img/sprite.svg#icon-check"></use>
          </svg>  
        </a>
        <a class="roleaccess__item__value" (click)="item.roleacc_is_delete=item.roleacc_is_delete==0 ? 1: 0" [ngClass]="{'roleaccess__item__value--yes':item.roleacc_is_delete==1,'roleaccess__item__value--no':item.roleacc_is_delete==0}">
          <svg>
            <use xlink:href="./assets/img/sprite.svg#icon-check "></use>
          </svg>
        </a>
      </div>
    </div>
  </div>
</div>

