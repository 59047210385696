<div class="search">
    <input
      type="text"
      class="search__input"
      placeholder="Search Database(s)"
      (keyup)="searchDatabase($event)"
    />
    <button class="search__button">
      <svg class="search__icon">
        <use xlink:href="./assets/img/sprite.svg#icon-search"></use>
      </svg>
    </button>
  </div>

  <app-database-table TableID="1"></app-database-table>



