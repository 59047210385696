import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { BehaviorSubject, Subject } from 'rxjs';
import { dyndataobject_model } from 'src/app/_model/dyndataobject_model';
import { ConstantsService } from 'src/app/_services';
import { DatasearchService } from 'src/app/_services/datasearch.service';
import { ExportDataService } from 'src/app/_services/export-data.service';
import {IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import { ToastserviceService } from 'src/app/_services/toastservice.service';
import { CryptoService } from 'src/app/_services/crypto.service';

@Component({
  selector: 'app-dyntable-data-public',
  templateUrl: './dyntable-data-public.component.html',
  styleUrls: ['./dyntable-data-public.component.scss']
})
export class DyntableDataPublicComponent implements OnInit {
  @Input() TableID: number = 0;
  @Input() TableReportTitle: string;
  @Input() tableSettingsSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>({
      menu_is_mstr_glbsearch: true,
      menu_is_mstr_downloadcsv: true,
      menu_is_mstr_downloadexcel: true,
      menu_is_mstr_downloadpdf: true,
      roleacc_is_view: false,
      roleacc_is_edit: false,
      roleacc_is_delete: false,
    });
  @Output() onAddNew = new EventEmitter<any>();
  @Output() onEdit = new EventEmitter<any>();
  @Output() onView = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();
  @Output() onApprove = new EventEmitter<any>();
  @Input() refreshTableSubject: Subject<boolean> = new Subject<boolean>();
  @Input() approvalcount: number = 0;

  userid: number = 0;
  postdata: dyndataobject_model;
  TableSettings: any;
  showApproveView: boolean = false;
  showData:boolean=false;

  constructor(
    private _constant: ConstantsService,
    private exportdataService: ExportDataService,
    private datasearchService: DatasearchService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastService: ToastserviceService,
    private cryptoService:CryptoService
  ) {

  }

  ngOnInit(): void {
    // debugger;
    this.userid = 0;
    this.TableSettings={menu_is_mstr_glbsearch: true,
      menu_is_mstr_downloadcsv: true,
      menu_is_mstr_downloadexcel: true,
      menu_is_mstr_downloadpdf: true,
      roleacc_is_view: true,
      roleacc_is_edit: false,
      roleacc_is_delete: false};
    
    
   

    this.route.params.subscribe((params) => {
      this.TableID = +params['id'];
      // Get Details From Database Based on table ID
      this.postdata = {
        mode: 'GET_TABLE_DYNDATA',
        userid: 1,
        table_id: this.TableID,
        filter_condition: '',
        sortby: '',
        rowscount: 15,
        pagenumber: 1,
        table_action: '',
      };
      // let testenc=this.cryptoService.encrtyptData(this.postdata);
      // console.log(`enc data : ${testenc}`);
      // let testdec=this.cryptoService.decryptData(testenc);
      // console.log(`dec data :`,testdec);
      this.getData(this.postdata);

      this.refreshTableSubject.subscribe((response) => {
        if (response) {
          this.getData(this.postdata);
        }
      });
    });
  }

  //Table Variables
  TableName: string = '';
  rows: number = 15;
  totalRecords: number;
  loading_table: boolean;
  products_current_datasource: any[] = [];
  cols: any[];
  cols_form: any[];
  current_pagenumber: number = 1;
  IsShowFullPageData: boolean = false;
  IsNoRecordsFound: boolean = true;
  IsFilterAplied: boolean = false;
  globalfilterFields: string[] = [];
  currentFilterCondition: string = '';
  globalFilterCondition: string = '';
  currentSortBy: string = '';
  rptDataForExcel = [];
  tablefilter: any = {};
  first = 0;
  @ViewChild('dt') dataTable: Table;
  isLastPage_var: boolean = false;
  isFirstPage_var: boolean = false;

  next() {
    //this.first = this.first + this.rows + 1;
    this.setCurrentPage(this.current_pagenumber + 1);
  }
  prev() {
    //this.first = this.first + this.rows + 1;
    this.setCurrentPage(this.current_pagenumber - 1);
  }
  isLastPage() {

    this.isLastPage_var = this.products_current_datasource
      ? this.first ===
        (this.totalRecords - this.rows < 0 ? 0 : this.totalRecords - this.rows)
      : true;
  }
  isFirstPage() {
    this.isFirstPage_var = this.products_current_datasource
      ? this.first === 0
      : true;
  }

  setCurrentPage(n: number) {
    let paging = {
      first: (n - 1) * this.dataTable.rows,
      rows: this.dataTable.rows,
    };
    this.dataTable.onPageChange(paging);
  }
  paginate(event) {
    
    //event.first: Index of first record being displayed
    //event.rows: Number of rows to display in new page
    //event.page: Index of the new page
    //event.pageCount: Total number of pages
    let pageIndex = event.first / event.rows + 1; // Index of the new page if event.page not defined.
  }

  // LAZY LOAD EVENT TABLE
  loadLazyData(event: LazyLoadEvent) {
    this.loading_table = true;
    
    this.current_pagenumber = event.first / event.rows + 1;
    this.tablefilter = event.filters;
    //debugger;
    let tblConditions = this._constant.getTablesqlFilterCondition(
      this.tablefilter
    );
    if (this.globalFilterCondition != '' || tblConditions != '') {
      let firstcondition =
        ' WHERE ' +
        this.globalFilterCondition +
        ((tblConditions != '' && this.globalFilterCondition != '') == true
          ? ' AND '
          : ' ');
      this.currentFilterCondition = firstcondition + tblConditions;
    } else {
      this.currentFilterCondition = '';
    }

    //SORT BY CONDITION
    if (event.sortField) {
      this.currentSortBy = ` ORDER BY ${event.sortField} ${
        event.sortOrder == 1 ? ' ASC' : ' DESC'
      }`;
    } else {
      this.currentSortBy = '';
    }
    setTimeout(() => {
      this.postdata.rowscount = event.rows;
      this.postdata.pagenumber = this.current_pagenumber;
      this.postdata.filter_condition = this.currentFilterCondition;
      this.postdata.sortby = this.currentSortBy;

      this.getData(this.postdata);
    }, 1000);
  }

  //On Add New Record
  onAddNewEvent() {
    //let form_data = { row_data: [], form_fielddata: this.cols_form };
    //this.onAddNew.emit(form_data);
    this.formEditMode="ADD";
    this.showForm();
  }

  //On View Record
  onViewEvent(row_data) {
    //let form_data = { row_data: row_data, form_fielddata: this.cols_form };
    this.field_data=row_data;
    this.formEditMode="VIEW";
    this.showForm();
    //this.onView.emit(form_data);
  }

  //On Edit Record
  onEditEvent(row_data) {
    //let form_data = { row_data: row_data, form_fielddata: this.cols_form };
    //this.onEdit.emit(form_data);
    this.edit_data=row_data;
    this.field_data=row_data;
    this.formEditMode="EDIT";
    this.showForm();
  }

  //On Delete Record
  onDeleteEvent(row_data) {
    //this.onDelete.emit(row_data);
    this.edit_data=row_data;
    this.formEditMode="DELETE";
    this.toastService.showConfirm("Confirm","Are sure want to delete the record?",(isconfiremed)=>{
      if(isconfiremed){
        this.ondynFormSubmit();
      }
    })
    
  }

  //On Approve Record
  onApproveEvent(row_data) {
    this.onApprove.emit(row_data);
  }

  onApproveViewTblEvent(apview) {
    this.showApproveView = apview;
    this.postdata.table_action =
      this.showApproveView == true ? 'FOR APPROVAL' : '';
    this.first = 0;
    this.products_current_datasource = [];
    this.totalRecords = 0;
    this.getData(this.postdata);
  }

  onGlobalSearch(val) {
    this.globalFilterCondition = '';
    let searchvalue = val.trim();
    let gConditions = [];
    this.cols.forEach((item) => {
      let makcondition = `${item.field_db_col_name} LIKE ''%${searchvalue}%''`;
      gConditions.push(makcondition);
    });
    this.globalFilterCondition = gConditions.join(' OR ');
    let tblConditions = this._constant.getTablesqlFilterCondition(
      this.tablefilter
    );
    if (this.globalFilterCondition != '' || tblConditions != '') {
      let firstcondition =
        ' WHERE ' +
        this.globalFilterCondition +
        ((tblConditions != '' && this.globalFilterCondition != '') == true
          ? ' AND '
          : ' ');
      this.currentFilterCondition = firstcondition + tblConditions;
    } else {
      this.currentFilterCondition = '';
    }

    this.postdata.pagenumber = 1;
    this.postdata.filter_condition = this.currentFilterCondition;

    this.getData(this.postdata);
  }

  //Export Data Excel/Csv
  exportExcel(extention) {
    // if (this.currentFilterCondition == '') {
    //   return;
    // }

    this.postdata.rowscount = this.totalRecords;
    this.datasearchService.manageDynTablemgmtPublic(this.postdata).subscribe(
      (response) => {
        //console.log(response);

        let DownloadData = response[0];
        this.rptDataForExcel = [];
        DownloadData.forEach((row: any) => {
          this.rptDataForExcel.push(Object.values(row));
        });
        let header_temp = [];
        //Add id field as ref no
        header_temp.push('Ref.No');
        
        this.cols.map((col) => {
          header_temp.push(col.field_ui_display_name);
        });
        let reportData = {
          title: `${this.TableReportTitle} Data Report`,
          data: this.rptDataForExcel,
          headers: header_temp,
          sheetname: 'data',
          file_extention: extention,
        };

        this.exportdataService.exportExcel(reportData);
      },
      (error) => {
        console.log(error);
      },
      () => {
        this.loading_table = false;
      }
    );
  }

  //Export Pdf
  exportPdf() {
    let filterconditions_data = this.currentFilterCondition;
    // if (filterconditions_data == '') {
    //   return;
    // }

    this.postdata.rowscount = this.totalRecords;
    //this.postdata.rowscount=10;

    this.datasearchService.manageDynTablemgmtPublic(this.postdata).subscribe(
      (response) => {
        //console.log(response);
        let DownloadData = response[0];
        this.rptDataForExcel = [];
        DownloadData.forEach((row: any) => {
          this.rptDataForExcel.push(Object.values(row));
        });

        let columns = this.cols.map((col) => ({
          title: col.field_ui_display_name,
          dataKey: col.field_db_col_name,
        }));
        columns.splice(0, 0, { title: 'Ref.No', dataKey: 'id' });
        let reportData = {
          title: `${this.TableReportTitle} Data Report`,
          data: this.rptDataForExcel,
          headers: columns,
          sheetname: 'data',
        };

        this.exportdataService.exportPdf(reportData);
      },
      (error) => {
        console.log(error);
      },
      () => {
        this.loading_table = false;
      }
    );
  }

  ////////// PAGE LEVEL METHODS /////
  getData(postdata: dyndataobject_model) {
    this.datasearchService
      .manageDynTablemgmtPublic(this.postdata)
      .subscribe((res) => {
        
        console.log(res);
        let dyndata: any = res;
        //Table Raw data
        this.products_current_datasource = dyndata[0];
        //Column Details
        this.cols = dyndata[1];
        this.field_details=dyndata[1];
        this.cols.forEach((x) => {
          if (
            x.field_json_dropdownvalues != '' &&
            x.field_json_dropdownvalues != null
          ) {
            x.field_sql_data = JSON.parse(x.field_json_dropdownvalues);
          }
        });
        //this.cols_form = dyndata[2];
        this.totalRecords = dyndata[2][0].countval;
        this.TableReportTitle = dyndata[2][0].table_display_name;
        this.globalfilterFields = this.cols.map((item) => item.field);
        if (this.totalRecords == 0) {
          this.IsNoRecordsFound = true;
          //this.toastr.info('No record(s) found for current filter criteria!');
        } else {
          this.IsNoRecordsFound = false;
        }
        this.isFirstPage();
        this.isLastPage();
        this.loading_table = false;
      });
  }

  findNamefrmID(rowData, field_db_col_name) {
    let data = this.cols.find(
      (x) => x.field_db_col_name == field_db_col_name
    ).field_sql_data;
    let finddata = data.find((x) => x.id == rowData[field_db_col_name]);
    if (finddata) {
      return data.find((x) => x.id == rowData[field_db_col_name]).name;
    }
    return rowData[field_db_col_name];
  }


  //#region  FORM
  dynForm: FormGroup;
  formEditMode: string="ADD"; //ADD,EDIT,DELETE,VIEW
  field_details: any[];
  field_data:any;
  edit_data:any;

  createForm(){
    this.dynForm=null;
    // CREATE DYNAMIC FORM
    let fb_builder = {};
    this.field_details.forEach((item) => {

      

      let temp_validator=[];
      if(item.field_ui_mandatory == true){
        temp_validator.push(Validators.required);
      }
      if(item.field_ui_regex !='' && item.field_ui_regex !=null){
        temp_validator.push(Validators.pattern(`${item.field_ui_regex}`));
      }
      if(item.field_db_size!='' && item.field_db_size !=null){
        temp_validator.push(Validators.maxLength(item.field_db_size));
      }

      fb_builder[item.field_db_col_name] = [
        //Assign Actual/Default value
        this.formEditMode=="ADD" ? (item.field_ui_defalutvalue=='' ? null : item.field_ui_defalutvalue) : this.field_data[item.field_db_col_name],
        //Assigning Validation
        Validators.compose(temp_validator),
      ];
    });
    this.dynForm = this.formBuilder.group(fb_builder);

    // //Disable the form in View mode
    // if(this.formEditMode=="VIEW"){
    //   this.dynForm.disable();
    // }
    // else{
    //   this.dynForm.enable();
    // }
  }

  showForm(){
    this.createForm();
    this.showData=true;
  }
  ondynFormSubmit() {
    // debugger;
    let editvalue=this.formEditMode=="DELETE" ? {}:this.dynForm?.value;
    if(this.edit_data.ID!=null){
      editvalue.id=this.edit_data.ID;
    }
    
    console.log(editvalue);

    let modesql="";
    let delete_data_id="";
    let message="";
    switch (this.formEditMode) {
      case "ADD":
        modesql="UPLOAD_TABLE_DATA";
        message="Data Added Successfully.";
        break;
      case "EDIT":
          modesql="UPDATE_TABLE_DATA";
          message="Data Updated Successfully.";
        break;
      case "DELETE":
          modesql="DELETE_TABLE_DATA";
          delete_data_id=editvalue.id;
          message="Data Deleted Successfully.";
        break;  
      default:
        break;
    }

    let Templatedata=[];
    Templatedata.push(editvalue);
    const regex_formesc = /\\/gm;
    let formescapestring=JSON.stringify(Templatedata);
    formescapestring=formescapestring.replace(regex_formesc,'\\\\');
    let uploaddata=JSON.parse(formescapestring);
    let postdata ={ 
      mode:modesql,
      userid:this.userid,
      table_id:this.TableID,
      table_data:uploaddata,
      emptytable:0,
      data_id:delete_data_id
    };

    this.datasearchService.manageDynTablemgmtPublic(postdata).subscribe(res=>{
      this.showData=false;
      this.getData(this.postdata);
      this.toastService.showSuccess(
        'Success',
        message
      );
    },(error)=>{
      console.log(error);
      this.toastService.showError(
        'Error!',
        'Something went wrong while getting table details. Please refresh the page and try again!'
      );
    },()=>{

    });





  }
  //#endregion
}
