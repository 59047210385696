<div class="dbcoltable">
  <h1 class="master__pageheading">{{ this.pageTitle }}</h1>
  <div class="dbcoltable__box">
    <div class="dbcoltable__box__header">
      <div class="">
        <h3>Database column details</h3>
        <p>Select the checkbox to show/hide the column configuration</p>
        <p>Drag the row to change the column order</p>
      </div>
      <div class="">
        <label class="u-text-medium">Select Table</label>
        <p-autoComplete
          [(ngModel)]="selectedTable"
          [dropdown]="true"
          [suggestions]="filteredTables"
          (completeMethod)="filterTable($event)"
          (onSelect)="onSelectTable($event)"
          field="TableName"
          placeholder="Database Table(s)"
        >
        <ng-template let-table pTemplate="item">
          <div>{{ table.TableDisplayName }}</div>          
      </ng-template>
      </p-autoComplete>
      </div>
      <div class="u-show-flex u-show-flex__column">
        <label class="u-text-medium">Default Grid Rows Count</label>
        <p-inputNumber
        [(ngModel)]="Default_Grid_RowsCount"
        mode="decimal"
        inputId="withoutgrouping"
        [useGrouping]="false"
        [min]="0" [max]="100000"
        >
        </p-inputNumber>
      </div>
      <div class="u-show-flex u-show-flex__column">
        <label class="u-text-medium">Default Download Limit</label>
        <p-inputNumber
        [(ngModel)]="Default_DownloadRec_Limit"
        mode="decimal"
        inputId="withoutgrouping"
        [useGrouping]="false"
        [min]="0" [max]="100000"
        >
        </p-inputNumber>
      </div>
      <div class="u-show-flex u-margin-left-auto">
        <button
          type="submit"
          class="btn btn--green u-margin-right-small"
          (click)="updateColumnDetail()"
        >
          <svg class="btn__icon">
            <use xlink:href="./assets/img/sprite.svg#icon-check-square"></use>
          </svg>
          &nbsp;Save
        </button>
        <button
          type="submit"
          class="btn btn--white"
          (click)="resetColumdetail()"
        >
          <svg class="btn__icon">
            <use xlink:href="./assets/img/sprite.svg#icon-rotate-cw"></use>
          </svg>
          &nbsp;Reset
        </button>        
      </div>
    </div>
    <p-table *ngIf="tblColumnsDtls && tblColumnsDtls.length" [value]="tblColumnsDtls" [columns]="cols" [reorderableColumns]="true" responsiveLayout="scroll" scrollHeight="500px">
      <ng-template pTemplate="header" let-columns>
          <tr>
              <th style="width:3rem"></th>
              <th pReorderableColumn *ngFor="let col of columns">
                <ng-container *ngIf="col.field !== 'view_in_dropdown' && col.field !== 'column_color' && col.field !== 'show_popup'">
                  {{col.header}}
                </ng-container>
                <ng-container *ngIf="col.field === 'column_color' && tables[selectedTable.TableName]?.color">
                  {{col.header}}
                </ng-container>
                <ng-container *ngIf="col.field === 'view_in_dropdown' && tables[selectedTable.TableName]?.dropdown">
                  {{col.header}}
                </ng-container>
                <ng-container *ngIf="col.field === 'show_popup' && tables[selectedTable.TableName]?.popup">
                  {{col.header}}
                </ng-container>
                <!-- <ng-container *ngIf="(dropdownManage?true:col.field !== 'view_in_dropdown') && (col.type !== 'color')">
                  {{col.header}}
                </ng-container>   
                <ng-container *ngIf="(selectedTable.Tabledetails==='AGROPHARM_XMIM_MONGO') && (col.type === 'color')">
                  {{col.header}}
                </ng-container>                 -->
              </th>              
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
          <tr [pReorderableRow]="index">
              <td>
                  <span class="pi pi-bars" pReorderableRowHandle></span>
              </td>
              <td *ngFor="let col of columns">
                <ng-container *ngIf="col.type==='text'">
                  <input
                    class="inp-stl"
                    type="text"
                    placeholder="Enter New Column Name"
                    [(ngModel)]="rowData[col.field]"
                    readonly
                    disabled
                    *ngIf="col.field==='ExcelColumnName'"
                  />
                  <input
                    class="inp-stl"
                    type="text"
                    placeholder="Enter New Column Name"
                    [(ngModel)]="rowData[col.field]"
                    *ngIf="col.field==='DisplayColumnName'"
                  />
                </ng-container>
                <ng-container *ngIf="col.type==='number'">
                  <p-inputNumber
                    [(ngModel)]="rowData[col.field]"
                    mode="decimal"
                    inputId="withoutgrouping"
                    [useGrouping]="false"
                    [min]="0" [max]="700" suffix=" px"
                    *ngIf="col.field==='ui_width_temp'"
                  >
                  </p-inputNumber>
                  <p *ngIf="col.field==='display_order'">{{rowData[col.field]}}</p>
                </ng-container>                  

                <ng-container *ngIf="col.type==='boolean' && col.field === 'Is_display'">
                  <input
                    type="checkbox"
                    [id]="'showdrop' + col.field + i"
                    [(ngModel)]="rowData[col.field]"
                  />
                  <label [for]="'showdrop' + col.field + i"></label>
                </ng-container>

                <ng-container *ngIf="col.type==='boolean' && col.field === 'view_in_dropdown' && tables[selectedTable.TableName]?.dropdown">
                  <input
                    type="checkbox"
                    [id]="'showdrop' + col.field + i"
                    [(ngModel)]="rowData[col.field]"
                  />
                  <label [for]="'showdrop' + col.field + i"></label>
                </ng-container>
                <ng-container *ngIf="col.type==='boolean' && col.field === 'show_popup' && tables[selectedTable.TableName]?.popup">
                  <input
                    type="checkbox"
                    [id]="'showdrop' + col.field + i"
                    [(ngModel)]="rowData[col.field]"
                  />
                  <label [for]="'showdrop' + col.field + i"></label>
                </ng-container>
                <ng-container *ngIf="col.type==='color' && col.field === 'column_color' && tables[selectedTable.TableName]?.color">
                  <p-colorPicker [(ngModel)]="rowData[col.field]"></p-colorPicker>
                </ng-container>
              </td>
          </tr>
      </ng-template>
    </p-table>
  </div>  
</div>