import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { filter_model } from '../_model/filter_model';
import { ConstantsService } from './constants.service';

@Injectable({
  providedIn: 'root'
})
export class DatasearchService {
  
  constructor(private http:HttpClient,private constants:ConstantsService) { }


  Get_Template_Tables()
  {
    return this.http.post(`${this.constants.betaApiUrl}/get_tables`,"test");
  }

  Get_Template_Tables_mongo()
  {
    return this.http.post(`${this.constants.betaApiUrl}/get_tables_mongo`,"test");
  }

  Update_TableDtls(postdata)
  {
    return this.http.post(`${this.constants.ApiUrl}/update_tables`,postdata);
  }
  
  Update_TableDtls_mongo(postdata)
  {
    return this.http.post(`${this.constants.betaApiUrl}/update_tables_mongo`,postdata);
  }


  // Get Table COlumn Values Dynamically
  GetDropDownValues(postdata,hideloader=false)
  {
    return this.http.post<any[]>(this.constants.ApiUrl+'/get_dropdownvalues',postdata,{params:{ishideloader:hideloader}}).pipe(
      map(response=>{
        return response;
      })
    );
  }

  // Get Table COlumn Values Dynamically
  GetChemroboticsData(postdata)
  {
    return this.http.post(this.constants.ApiUrl+'/get_chemroboticsdata',postdata);
  
  }

  // Get columns from Template
  GetTemplateCols(postdata)
  {
    return this.http.post<any>(this.constants.ApiUrl+'/get_template',postdata);
  
  }

  // Get Table Column Values Dynamically
  GetDynTableData(postdata)
  {
    return this.http.post(this.constants.ApiUrl+'/dyn_master',postdata);
  }
  //Get Dynamic Master Data -- Employee doc manage
   GetDynEmpDocManage(postdata)
   {
     return this.http.post(this.constants.ApiUrl+'/dynemp_doc_manage',postdata);
   }

  // manage Role Access
  manageRoleAccess(postdata)
  {
    return this.http.post(this.constants.ApiUrl+'/role_access',postdata);
  }

  // manage dynamic table managemnet
  manageDynTablemgmt(postdata)
  {
    return this.http.post(this.constants.ApiUrl+'/dyn_table_manage',postdata);
  }
  async manageDynTablemgmt_sync(postdata)
  {
    let resultdata:any;
    let promise =new Promise<any>((resolve,reject)=>{
      this.http.post(`${this.constants.ApiUrl}/dyn_table_manage`,postdata).toPromise().then(
        res=>{

          //Return Result
          resultdata=res;
          resolve(res);
        },
        msg=>{ //Error
          reject(msg);
        }
      );
    });

    return await promise ;
  }
  manageDynTablemgmtPublic(postdata)
  {
    return this.http.post(this.constants.ApiUrl+'/dyn_table_manage_public',postdata);
  }

  // manage dynamic table managemnet
  manageTemplateColumn(postdata)
  {
    return this.http.post(this.constants.ApiUrl+'/managetemplatecolumn',postdata);
  }

   // manage dynamic table managemnet mongo
   manageTemplateColumn_mongo(postdata)
   {
     return this.http.post(this.constants.betaApiUrl+'/managetemplatecolumn_mongo',postdata);
   }

  //Get Dropdowm Values Synchronous
  GetDropdownValuesSyn(postdata):Promise<any>{
    return this.http.post(this.constants.ApiUrl+'/dyn_master',postdata).toPromise();
  }

  // manage Role Access
  sendMail(postdata)
  {
    return this.http.post(this.constants.ApiUrl+'/sendmail',postdata);
  }

  // upload file
  uploadFile(postdata)
  {
    return this.http.post(this.constants.ApiUrl+'/uploadfile',postdata);
  }
  uploadFiles(postdata)
  {
    return this.http.post(this.constants.ApiUrl+'/uploadfiles',postdata);
  }
  async uploadFiles_sync(postdata)
  {
    let resultdata:any;
    let promise =new Promise<any>((resolve,reject)=>{
      this.http.post(this.constants.ApiUrl+'/uploadfiles',postdata).toPromise().then(
        res=>{

          //Return Result
          resultdata=res;
          resolve(res);
        },
        msg=>{ //Error
          reject(msg);
        }
      );
    });

    return await promise ;
  }


  // Manage Invoice 
  manageInvoice(postdata,hideloader=false)
  {
    return this.http.post(this.constants.ApiUrl+'/invoice_manage',postdata,{params:{ishideloader:hideloader}});
  }


 //Technical Route GBRN Count Statistics
  technicalRouteProcessGBRNCount(postdata,hideloader=false)
  {
    return this.http.post(this.constants.ApiUrl+'/technicalroute_process_gbrn_count',postdata,{params:{ishideloader:hideloader}});
  }

  //Get table data
  getTableData(postdata){
    return this.http.post(this.constants.betaApiUrl+'/get_table_data',postdata);
  }

  //Get table data count
  getTableDataCount(postdata){
    return this.http.post(this.constants.betaApiUrl+'/table_data_count',postdata);
  }

  //Update table data
  updateTableData(postdata){
    return this.http.post(this.constants.betaApiUrl+'/update_table_data',postdata);
  }

  //Technical Route GBRN Count Statistics
  common_config(postdata,hideloader=false)
  {
    return this.http.post(this.constants.ApiUrl+'/common_config',postdata,{params:{ishideloader:hideloader}});
  }

}
