import { AnimateChildOptions } from '@angular/animations';
import { Template } from '@angular/compiler/src/render3/r3_ast';
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
//Import FOr File upload
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from 'ngx-file-drop';
//Import for alert
import Swal from 'sweetalert2';
//Import for Xlsx file read
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

//For service
import { FileuploadService } from './fileupload.service';
// For dialog box
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import {
  ConfirmationService,
  ConfirmEventType,
  MessageService,
} from 'primeng/api';

const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
  customClass: {
    container: 'alert__container',
    //header: 'alert__header',
  },
});

//Interface
interface ITemplate {
  TableColumnName: string;
  ExcelColumnName: string;
  field_data_type?: string;
}
interface IDiffCol {
  SNO: string;
  InputColumnName: string;
  ActualColumnName: string;
  TableColumnName: string;
  field_data_type?: string;
}
interface IUploadedStatusData {
  filename: string;
  IsValidFile: boolean;
  TotalRecords: number;
  SccessRecords: number;
  ErroRecords: number;
  MismatchColums: IDiffCol[];
  TemplatefieldsCount: number;
  FileFieldsCount: number;
  StatusMessage: string;
}

@Component({
  selector: 'app-fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class FileuploadComponent implements OnInit {
  @Input() Title: string;
  @Input() Tablename: string;
  @Input() Table: string;
  @Input() Templatetable: string;
  @Input() StoredProcedure: string = '';
  @Input() IsTruncateBeforeUpload: boolean = false;
  @Input() IsMongoDBTable: boolean = false;

  @Output() onUploadCompleted = new EventEmitter<any>();

  Filename: string = ' Support only xlsx format file.';
  IsUploading: boolean = false;
  UploadStatus: string = 'Uploading..';
  CurrentFileTotalRecords: number = 0;
  CurrentProcessRecords: number = 0;
  public files: NgxFileDropEntry[] = [];
  ShowUploadResult: boolean = false;
  Templatefields: ITemplate[] = [];
  TemplateTablefields = [];
  FileFields: any = [];
  columns = [
    { prop: 'SNO', name: 'S.No' },
    { prop: 'ActualColumnName', name: 'Actual Column' },
    { prop: 'InputColumnName', name: 'Input Column' },
  ];
  UploadedStatusData: IUploadedStatusData[] = [];
  Totalfiles: number = 0;
  UploadedFiles: number = 0;

  constructor(
    private service: FileuploadService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {}

  //Download Template
  async Download_Template() {
    var wb = XLSX.utils.book_new();

    let TemplateData = {};
    let Templatetablenames = this.Templatetable.split(',');
    for (
      let tab_index = 0;
      tab_index < Templatetablenames.length;
      tab_index++
    ) {
      let table_name = Templatetablenames[tab_index];
      let Templatefields = await this.GetTempateFields({
        tablename: table_name,
      });
      Templatefields.forEach((col) => {
        TemplateData[col.ExcelColumnName.trim()] = '';
      });
    }

    var ws = XLSX.utils.json_to_sheet([TemplateData], { skipHeader: false });

    XLSX.utils.book_append_sheet(wb, ws);

    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(
      new Blob([wbout], { type: 'application/octet-stream' }),
      this.Title + '.xlsx'
    );
  }

  async dropped(files: NgxFileDropEntry[]) {
    this.TemplateTablefields = [];
    this.UploadedStatusData = [];
    this.IsUploading = true;
    this.UploadStatus = 'Uploading..';
    let uploadTable = '';

    //Get Template Fields Array for Each Tables
    let maintablenames = this.Tablename.split(',');
    if (this.StoredProcedure != null && this.StoredProcedure.trim() != '') {
      for (let tab_index = 0; tab_index < maintablenames.length; tab_index++) {
        await this.service.EmptyTableData({
          tablename: maintablenames[tab_index],
        });
      }
    }

    let Templatetablenames = this.Templatetable.split(',');
    for (
      let tab_index = 0;
      tab_index < Templatetablenames.length;
      tab_index++
    ) {
      let table_name = Templatetablenames[tab_index];
      let Templatefields = await this.GetTempateFields({
        tablename: table_name,
      });
      this.TemplateTablefields.push(Templatefields);
    }

    let TemplatefieldsCount: number = 0;
    for (
      let index_cols = 0;
      index_cols < this.TemplateTablefields.length;
      index_cols++
    ) {
      TemplatefieldsCount += this.TemplateTablefields[index_cols].length;
    }
    this.files = files;
    this.Totalfiles = files.length;
    this.UploadedFiles = 0;
    var reader = new FileReader();
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        let file = await this.GetFile(fileEntry);
        let filename = '';
        let totalrecords = 0;
        let errorrecords = 0;
        let IsValidFile = false;
        let FileFieldsCount: number = 0;
        let StatusMessage: string;
        let MismatchColums: IDiffCol[] = [];
        let MatchedColums: IDiffCol[] = [];

        this.IsUploading = true;
        let ext = droppedFile.relativePath.substring(
          droppedFile.relativePath.lastIndexOf('.') + 1
        );

        this.Filename = droppedFile.relativePath;
        filename = droppedFile.relativePath;

        if (ext == 'xlsx') {
          IsValidFile = false;

          //reader.readAsBinaryString(file);
          let filedata = await this.GetFileReaderResult(file);

          //reader.onload = async (data) => {
          //filedata = reader.result;
          var wb = XLSX.read(filedata, { type: 'binary' });
          var wsname = wb.SheetNames[0];
          var ws = wb.Sheets[wsname];

          let jsondata = XLSX.utils.sheet_to_json(ws, {
            header: 1,
            defval: '',
          });

          this.FileFields = jsondata[0];
          FileFieldsCount = this.FileFields.length;

          //Loop through Sigle or Multiple Tables
          for (
            let tableIndex = 0;
            tableIndex < this.TemplateTablefields.length;
            tableIndex++
          ) {
            let CurrentTableName = maintablenames[tableIndex];
            MismatchColums = [];
            MatchedColums = [];
            this.Templatefields = this.TemplateTablefields[tableIndex];

            let tablecols: string[] = this.Templatefields.map(
              (item) => '`' + item.TableColumnName + '`'
            );
            let insertstring: string = '(' + tablecols.join(',') + ')';

            if (this.Templatefields.length > 0) {
              this.Templatefields.map((item, index) => {
                let filecolname: string =
                  this.FileFields[index] != null
                    ? this.FileFields[index].trim()
                    : '';
                let databasecolname: string = item.ExcelColumnName;
                let tablecolname: string = item.TableColumnName;

                let fieldDataType = item.field_data_type;

                //Check column Exists
                let findactualcol = this.FileFields.find(
                  (col) =>
                    col.trim().toLowerCase() ==
                    databasecolname.trim().toLowerCase()
                );

                if (findactualcol != null) {
                  MatchedColums.push({
                    SNO: (index + 1).toString(),
                    InputColumnName: filecolname,
                    ActualColumnName: databasecolname,
                    TableColumnName: tablecolname,
                    field_data_type: fieldDataType,
                  });
                } else {
                  MismatchColums.push({
                    SNO: (index + 1).toString(),
                    InputColumnName:
                      filecolname + `(${filecolname.length.toString()})`,
                    ActualColumnName:
                      databasecolname +
                      `(${databasecolname.length.toString()})`,
                    TableColumnName: tablecolname,
                  });
                }
              });

              let Templatedata = [];
              let Templatedata_mongo = [];
              let dateFields = [];
              let Tempdata = jsondata.slice(1);
              let Filefieldsfromfile = this.FileFields.map((item) =>
                item.toLowerCase().trim()
              );
              for (let i = 0; i < Tempdata.length; i++) {
                let data = [];
                let data_mongo = {};
                dateFields = MatchedColums.filter(
                  (val) => val.field_data_type === 'date'
                ).map((x) => x.TableColumnName);
                for (let mati = 0; mati < MatchedColums.length; mati++) {
                  let column =
                    MatchedColums[mati].ActualColumnName.toLowerCase().trim();
                  let fileindex = Filefieldsfromfile.indexOf(column);
                  let valueofcolumn = '';
                  if (fileindex >= 0) {
                    valueofcolumn = Tempdata[i][fileindex];
                  }
                  // valueofcolumn=valueofcolumn==null ? "":valueofcolumn;

                  // change made for if date not present then set null (24-10-24)
                  if (
                    dateFields.includes(MatchedColums[mati].TableColumnName)
                  ) {
                    valueofcolumn =
                      valueofcolumn === null ||
                      valueofcolumn === '' ||
                      valueofcolumn === undefined
                        ? null
                        : valueofcolumn;
                  } else {
                    valueofcolumn = valueofcolumn == null ? '' : valueofcolumn;
                  }

                  data.push(valueofcolumn);
                  data_mongo[MatchedColums[mati].TableColumnName] =
                    valueofcolumn;
                }
                Templatedata.push(data);
                data_mongo['created_at'] = new Date();
                Templatedata_mongo.push(data_mongo);
              }

              //Uplaod Data
              if (MismatchColums.length == 0) {
                let postdata = {
                  uploaddata:
                    this.IsMongoDBTable == false
                      ? Templatedata
                      : Templatedata_mongo,
                  tablename: CurrentTableName,
                  insertcols: insertstring,
                  is_truncatetable: this.IsTruncateBeforeUpload,
                  dateFields: dateFields,
                };

                totalrecords =
                  totalrecords +
                  postdata.uploaddata.length / this.TemplateTablefields.length;
                this.CurrentFileTotalRecords = totalrecords;

                //Empty The Table If Stored Procedure
                if (
                  this.StoredProcedure != null &&
                  this.StoredProcedure.trim() != ''
                ) {
                  await this.service.EmptyTableData({
                    tablename: CurrentTableName,
                  });
                }

                console.log('fgfgfhfh');
                console.log(postdata);

                let res = await this.UploadTemplateData(postdata);
                uploadTable = CurrentTableName;

                let resdata: any = res;
                if (resdata.status == 'error') {
                  StatusMessage = `Uplaod Failed - ${resdata.Message}`;
                  errorrecords += 1;
                } else {
                  this.onUploadCompleted.emit();
                  StatusMessage = 'Uploaded Successfully';
                }
              } else {
                StatusMessage = 'Invalid File';
                errorrecords += 1;
                break;
              }
            } else {
              console.log('No template fields.');
            }
          }
          //IF Stored Procedure present Have to run
          if (
            this.StoredProcedure != null &&
            this.StoredProcedure.trim() != ''
          ) {
            if (uploadTable === 'AgroPat_2') {
              await this.service
                .RunFileUploadProcedure({
                  storedprocedure: this.StoredProcedure,
                  IsEmpty: 1,
                })
                .then((response) => {
                  // debugger
                  if (response.status == 'error') {
                    errorrecords += 1;
                    StatusMessage = response.Message;
                  }
                })
                .catch((error) => {
                  // debugger
                  console.log(error);
                  errorrecords += 1;
                  StatusMessage = error.message;
                });
            } else {
              await this.service
                .RunFileUploadProcedure({
                  storedprocedure: this.StoredProcedure,
                  IsEmpty: 0,
                })
                .then((response) => {
                  // debugger
                  if (response.status == 'error') {
                    errorrecords += 1;
                    StatusMessage = response.Message;
                  }
                })
                .catch((error) => {
                  // debugger
                  console.log(error);
                  errorrecords += 1;
                  StatusMessage = error.message;
                });
            }
          }
        } else {
          IsValidFile = true;
          errorrecords += 1;
          StatusMessage = 'Invalid File.';
        }

        this.UploadedFiles += 1;

        this.UploadedStatusData.push({
          filename: filename,
          IsValidFile: IsValidFile,
          TotalRecords: totalrecords,
          SccessRecords: totalrecords - errorrecords,
          ErroRecords: errorrecords,
          MismatchColums: MismatchColums,
          TemplatefieldsCount: TemplatefieldsCount,
          FileFieldsCount: FileFieldsCount,
          StatusMessage: StatusMessage,
        });
        //End o Each file read

        //});
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }

    //Show File Upload Result
    this.IsUploading = false;
    this.Filename = ' Support only xlsx format file.';
    //this.ShowUploadResult = true;
  }

  public fileOver(event) {
    console.log(event);
  }

  public fileLeave(event) {
    console.log(event);
  }

  async GetFile(fileEntry: FileSystemFileEntry) {
    return new Promise<File>((resolve, reject) => {
      fileEntry.file(async (file: File) => {
        resolve(file);
      });
    });
  }

  async GetFileReaderResult(file: File) {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = (event: any) => {
        let filedata = reader.result;
        resolve(filedata);
      };
    });
  }

  //Page Level Functions
  async GetTempateFields(postdata): Promise<ITemplate[]> {
    let data: any;
    if (this.IsMongoDBTable == false) {
      data = await this.service.GetTemplateFields(postdata);
    } else {
      data = await this.service.GetTemplateFields_Mongo(postdata);
    }
    let templatefields: ITemplate[] = data;
    return templatefields;
  }

  //Each single data Process by this method
  async UploadTemplateData(PostData) {
    let data: any;
    if (this.IsMongoDBTable == false) {
      console.log(PostData);
      data = await this.service.UploadData(PostData);
    } else {
      data = await this.service.UploadData_Mongo(PostData);
    }
    return data;
  }

  //Show Error
  ShowError(message) {
    this.IsUploading = false;
    this.Filename = ' Support only xlsx format file.';
    Toast.fire({
      icon: 'error',
      title: message,
    });
  }

  //Show
  ShowStatus(resdata) {
    if (resdata.status == 'error') {
      this.UploadStatus = 'File uploaded Failed';
      Toast.fire({
        icon: 'error',
        title: this.UploadStatus,
      });
    } else {
      this.UploadStatus = resdata.Message;
      Toast.fire({
        icon: 'success',
        title: this.UploadStatus,
      });
    }
  }

  async deleteTableData() {
    if (this.IsMongoDBTable) {
      this.service
        .EmptyMongoTableData(this.Title)
        .then((res) => {
          this.messageService.add({
            severity: 'info',
            summary: 'Confirmed',
            detail: 'Records deleted successfully',
          });
        })
        .catch((error) => {
          this.messageService.add({
            severity: 'info',
            summary: 'Confirmed',
            detail: error.Message,
          });
        });
    } else {
      let tables = this.Tablename.split(',');
      if (this.Table === 'AgroPat') {
        let agroPatTables = [
          'AgroPat_1',
          'AgroPat_2',
          'agropat_summary',
          'agro_data_availiability',
          'biology',
          'commentry',
          'env_fate_sub_headings',
          'invent_info',
          'keywords',
          'lieatrature',
          'mammalian_toxicology',
          'mamm_human_eco_tox',
          'mamm_non_human_ecotoxicology',
          'products',
          'regulatory',
          'resistance_action_commitee',
          'safety_hazards',
          'toxicity',
          'tox_net_data',
        ];

        tables = tables.concat(agroPatTables);
      }
      for (let i = tables.length; i > 0; i--) {
        try {
          const res = await this.service.ClearTableData(tables[i - 1]);
          console.log(res);
          this.messageService.add({
            severity: 'info',
            summary: 'Confirmed',
            detail: 'Records deleted successfully',
          });
        } catch (error) {
          this.messageService.add({
            severity: 'info',
            summary: 'Confirmed',
            detail: error.Message,
          });
        }
      }
      // this.service.ClearTableData(tables).then((res)=>{
      //   this.messageService.add({severity:'info', summary:'Confirmed', detail:'Records deleted successfully'});
      // })
      // .catch(error => {
      //   this.messageService.add({severity:'info', summary:'Confirmed', detail: error.Message});
      // });
    }
  }

  confirm() {
    const mongoDB = this.IsMongoDBTable ? '(MongoDB)' : '';
    this.confirmationService.confirm({
      message: `Do you really want to delete whole data from ${this.Title} ${mongoDB} table?`,
      header: 'Clear Data Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.deleteTableData();
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            this.messageService.add({
              severity: 'error',
              summary: 'Rejected',
              detail: 'You have rejected',
            });
            break;
          case ConfirmEventType.CANCEL:
            this.messageService.add({
              severity: 'warn',
              summary: 'Cancelled',
              detail: 'You have cancelled',
            });
            break;
        }
      },
    });
  }
}
